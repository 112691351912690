import { Html, useTexture, useGLTF } from "@react-three/drei"
import * as THREE from 'three'

import { useControls } from 'leva'

// function PortalPlane (){

//     return(
//         <>
//             <mesh position-y={-0.001}  scale={4}
//                     rotation={[1.57,0,0]}
//                     // side={THREE.DoubleSide}
//                     onClick={ (event) => event.stopPropagation()}
//                     onPointerEnter={()=>document.body.style.cursor='default'}
//                 >
//                 <planeGeometry  />
//                 <meshBasicMaterial color={"yellow"}/>                
//             </mesh>
//         </>
//     )
// }


function WallFrame() {
    const wallFrame = useControls('Wall Frame', {

        position: { x: - 1.95, y: -0.23, z: 0.48 }
    })
    return (
        <>
            <group >
                <mesh position={[wallFrame.position.x, wallFrame.position.y, wallFrame.position.z]}>
                    <boxGeometry args={[0.1, 1.5, 2.95]} />
                    <meshBasicMaterial color={0xaaaaaa} />

                </mesh>
                <Html transform position={[wallFrame.position.x, wallFrame.position.y, wallFrame.position.z]}
                    distanceFactor={1.1}
                    rotation={[0, 1.57, 0]}
                >
                    <iframe src="https://my.matterport.com/show/?m=ijptWjgo5zw"
                        width={1073} height={525} title='wall_iframe'
                        allow='xr-spatial-tracking'
                    />
                </Html>
            </group>
        </>
    )
}


// import portalVertexShader from './shaders/portal/vertex.glsl'
function PortalShader() {

    console.log()
    return (
        <div>

        </div>
    )
}


//  function PortalTexture (){
//     const  {nodes} = useGLTF('./PortalWorld/portal/model/portal.glb')
//     // console.log(" useGLTF('./PortalWorld/portal/model/portal.glb') ", useGLTF('./PortalWorld/portal/model/portal.glb'))
//     // console.log("portal_gltf",portal_gltf.cameras);

//     const bakedTexture = useTexture('./PortalWorld/portal/model/baked.jpg')
//     bakedTexture.flipY = false

//     return (
//         <mesh 
//             // geometry={ nodes.baked.geometry }
//             >
//                 <meshBasicMaterial map={ bakedTexture } />
//         </mesh>
//     )
// }


export {
    WallFrame,
    // PortalPlane,
    PortalShader
    // PortalTexture
}