import { Card } from 'flowbite-react';

import BloodPressureIcon from '/icons/bp_icon.png'
import { Html } from '@react-three/drei';

// Define a TypeScript interface for the props
interface PatientVitalsProps {
    name: string;
    gender: string;
    weight: string;
    height: string;
    bloodPressure: string;
    conditions: string;
}




function PatientVitals({ name, gender, weight, height, bloodPressure, conditions }: PatientVitalsProps) {
    return (
        <>
            <Card className="w-80 p-4 shadow-lg">
                <h2 className="text-xl font-semibold mb-4">{name}'s Vitals </h2>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <p><strong>Gender:</strong> {gender}</p>
                        <p><strong>Weight:</strong> {weight} kg</p>
                        <p><strong>Height:</strong> {height} cm</p>
                    </div>
                    <div>
                        <p><strong>Blood Pressure:</strong>
                            <img src='./icons/bp_icon.png' alt="Blood Pressure" width="16" height="16" />
                            {bloodPressure}

                        </p>
                        <p><strong>Medical Conditions:</strong> {conditions}</p>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default PatientVitals;
