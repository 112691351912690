import {
    shaderMaterial, Sparkles, Center,
    useTexture, useGLTF, OrbitControls, Plane, Svg, Html, Environment
} from '@react-three/drei'

import * as THREE from 'three'
import { useFrame, extend } from '@react-three/fiber'
import { useRef, useState, useEffect } from 'react'
import { useControls } from 'leva'

import portalVertexShader from './shaders/portal/vertex.glsl'
import portalFragmentShader from './shaders/portal/fragment.glsl'

// import { vertexShader, fragmentShader } from './Shaders'

import AgoraRTC from 'agora-rtc-sdk';
import AgoraUIKit from "agora-react-uikit";



import { WallFrame } from '../../Components/PortalWorldComponents'
import PortalPlane from '../../Components/PortalPlane'
import AgoraIOVideoCall from '../../Components/AgoraIO'

// const NoiseMaterial = shaderMaterial(
//     {
//       scale: 1.5,
//       size: 0.2,
//       density: 4.0,
//       time: 0.0,
//       bg: new THREE.Color('#111033'),
//       yellow: new THREE.Color('#ffd600'),
//       orange: new THREE.Color('#ff7300'),
//     },
//     vertexShader,
//     fragmentShader
//   );

//   extend({ NoiseMaterial });

const PortalMaterial = shaderMaterial(
    {
        uTime: 0,
        uColorStart: new THREE.Color('#ffffff'),
        uColorEnd: new THREE.Color('#000000')
    },
    portalVertexShader,
    portalFragmentShader
)

extend({ PortalMaterial })

export default function PortalWorldExperience() {

    const agoraClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
    useEffect(() => {
        // Join the Agora channel here
        agoraClient.init('8f7fd0b1c01e40c09324fe2f249caa4b', () => {
            agoraClient.join('007eJxTYAh8OzUoZeNJq8ldh6xdNnms/ql2eJfeisxMsybHdP5qlakKDBZp5mkpBkmGyQaGqSYGyQaWxkYmaalGaUYmlsmJiSZJH4wfpzQEMjKck9dhZWSAQBCflyEgv6gkMcc5MScnMy+dgQEAgSUi4g==',
                'PortalCalling', null, (uid) => {
                    // Handle the successful join
                });
        });

        // Clean up when component unmounts
        return () => {
            agoraClient.leave(() => {
                agoraClient.unpublish();
                agoraClient.destroy();
            });
        };
    }, []);

    const [videoCall, setVideoCall] = useState(true);

    const rtcProps = {
        appId: "8f7fd0b1c01e40c09324fe2f249caa4b",
        channel: "PortalCalling",
        token: "007eJxTYAh8OzUoZeNJq8ldh6xdNnms/ql2eJfeisxMsybHdP5qlakKDBZp5mkpBkmGyQaGqSYGyQaWxkYmaalGaUYmlsmJiSZJH4wfpzQEMjKck9dhZWSAQBCflyEgv6gkMcc5MScnMy+dgQEAgSUi4g==",
    };

    const callbacks = {
        EndCall: () => setVideoCall(false),
    };

    // const material = useRef();
    const svgPath = '/logo1Verse.svg';

    // useFrame(({ clock }) => {
    //   material.current.uniforms.time.value = Math.sin(
    //     (2 * Math.PI * clock.getElapsedTime()) / 10
    //   );
    // })
    // const audioUrl = './PortalWorld/portal/music/music.mp3';
    const { nodes } = useGLTF('./PortalWorld/portal/model/portal.glb')
    console.log("nodesss", nodes)

    const bakedTexture = useTexture('./PortalWorld/portal/model/baked.jpg')
    bakedTexture.flipY = false

    const portalMaterial = useRef()

    const changePortal = () => {
        // Navigate to the specified URL when the object is clicked
        window.open('http://localhost:3000/apartment', '_self');
    };

    useFrame((state, delta) => {
        portalMaterial.current.uTime += delta
    })

    // const musicControl= useControls("music",{
    //     audioVolume: {
    //         value : 0.1,
    //         min: 0,
    //         max : 1,
    //         step: 0.01,  
    //     },
    //     loop:true,
    //     auto:true,
    //     play: true
    // })

    // const [ audio ] = useState(() => new Audio('./PortalWorld/portal/music/music.mp3'))
    // const playAudio=()=>{
    //     audio.play()
    //     console.log("audio", musicControl.auto)
    // }
    // const stopAudio=()=>{
    //     audio.pause()
    //     console.log("stop")
    // }


    // background music
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(true);
    const [volume, setVolume] = useState(0.3)

    useEffect(() => {
        // Create an audio element for the background music
        const audio = new Audio('./PortalWorld/portal/music/music.mp3');
        audio.loop = true;
        audio.volume = 0.3;
        audioRef.current = audio

        // Play the background music when the component mounts
        audio.play();

        // Pause the background music when the component unmounts
        return () => audio.pause();
    }, []);

    const { Music, Volume } = useControls('Audio', {
        Music: {
            value: isPlaying,
            toggle: () => setIsPlaying(!isPlaying)
        },
        Volume: {
            value: volume, min: 0, max: 1
        }
    });

    useEffect(() => {
        // Pause or play the background music based on the Music slider value
        if (audioRef.current) {

            if (Music === false) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            audioRef.current.volume = Volume;
            setVolume(Volume);
        }
    }, [Music, Volume]);

    //   useEffect(() => {
    //     // Pause or play the background music based on the Music slider value
    //     if (audioRef.current) {
    //         audioRef.current.volume = Volume;
    //         setVolume(Volume);
    //     }
    //   }, [Volume]);

    return <>
        <OrbitControls makeDefault />
        {/* <Plane args={[12, 14.75]}>
      <noiseMaterial ref={material} side={THREE.DoubleSide} />
    </Plane> */}
        <Environment background preset='sunset' />
        <Center>
            <mesh geometry={nodes.baked.geometry}>
                <meshBasicMaterial map={bakedTexture} />
            </mesh>
            {/* < PortalTexture /> */}
            <mesh geometry={nodes.poleLightA.geometry} position={nodes.poleLightA.position}
            // onClick={playAudio}
            >
                <meshBasicMaterial color="#ffffe5" />
            </mesh>
            <mesh geometry={nodes.poleLightB.geometry} position={nodes.poleLightB.position}>
                <meshBasicMaterial color="#ffffe5" />
            </mesh>
            <mesh geometry={nodes.portalLight.geometry}
                position={nodes.portalLight.position}
                // rotation={ nodes.portalLight.rotation }
                onClick={changePortal}
                // onClick={stopAudio}
                onPointerEnter={() => document.body.style.cursor = 'pointer'}
                onPointerLeave={() => document.body.style.cursor = 'default'}
            >
                <portalMaterial ref={portalMaterial} />
            </mesh>
            <Sparkles
                size={6}
                scale={[4, 2, 4]}
                position-y={1}
                speed={0.2}
                count={40}
            />
            <PortalPlane />
        </Center>
        <WallFrame />
        <group >
            {/* <mesh position={[1.95, -0.23, 0.48]}>
                <boxGeometry args={[0.1, 1.5, 2.95]} />
                <meshBasicMaterial color={0xaaaaaa} />
            </mesh> */}
            <Html transform position={[1.95, -0.23, 0.48]}
                distanceFactor={1.1}
            // rotation={[0, 1.57, 0]}
            >
                {/* <iframe
                    // src="https://my.matterport.com/show/?m=ijptWjgo5zw"
                    // width={1073} height={525} title='wall_iframe'
                    // allow='xr-spatial-tracking'
                    src="http://localhost:3000/video-calling-example"
                    width={1073}
                    height={525}
                    title="video_iframe"
                    allow="camera; microphone; fullscreen; display-capture"
                /> */}
                {/* {videoCall ? (
                    <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
                        <AgoraUIKit rtcProps={rtcProps} callbacks={callbacks} />
                    </div>
                ) : (
                    <h3 style={{ fontSize: "20", color: "yellow" }}
                        onClick={() => setVideoCall(true)}>Join</h3>
                )} */}

                <AgoraIOVideoCall />

            </Html>
        </group>



    </>
}