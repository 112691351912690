import React, { useState } from 'react'
import AgoraUIKit, { PropsInterface } from 'agora-react-uikit'
import * as THREE from 'react'

const AgoraIOVideoCall: React.FunctionComponent = () => {
  const [videoCall, setVideoCall] = useState(true)
  const props: PropsInterface = {
    rtcProps: {
      appId: "8f7fd0b1c01e40c09324fe2f249caa4b", //app_ID
      channel: "VideoCall", // channel
      token: "007eJxTYNCZ2xeYMeN3+ArBErWkV15SleWWT1PcOyWc/73ccezTflYFBos087QUgyTDZAPDVBODZANLYyOTtFSjNCMTy+TERJOkC7LPUxoCGRm0XqcyMjJAIIjPyRCWmZKa75yYk8PAAAAB3iHe", // pass in channel token in string format
    },

    callbacks: {
      EndCall: () => setVideoCall(false)
    }
  }
  return <>
    <h4>hello</h4>
    {videoCall ? (
      <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
        <AgoraUIKit rtcProps={props.rtcProps} callbacks={props.callbacks} />
      </div>
    ) : (
      <h3 onClick={() => setVideoCall(true)}>Join</h3>
    )}
    {/* Include the remote person's video call component */}
    {/* {videoCall && (
      <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
        <RemotePersonVideoCall />
      </div>
    )} */}

  </>
  // (
  //   <div style={styles.container}>
  //     {videocall ? (
  //       <AgoraUIKit
  //         rtcProps={props.rtcProps}
  //         callbacks={props.callbacks} />
  //     ) : (
  //       <h3 style={{ fontSize: "20", color: "yellow" }}
  //         onClick={() => setVideoCall(true)}>Join</h3>
  //     )}
  //   </div>
  // )
}

// const styles = {
//   container: { width: '100vw', height: '100vh', display: 'flex', flex: 1 },
// }

// const RemotePersonVideoCall: React.FunctionComponent = () => {
//   const props: PropsInterface = {
//     rtcProps: {
//       appId: "8f7fd0b1c01e40c09324fe2f249caa4b",
//       channel: "PortalCalling", // Use the same channel name as the host
//       // token: null, // Provide a valid token if required
//     },
//   };

//   return (
//     <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
//       <AgoraUIKit rtcProps={props.rtcProps} />
//     </div>
//   );
// };

export default AgoraIOVideoCall