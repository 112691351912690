import { Environment } from "@react-three/drei"
import * as THREE from 'three'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

/* 
TODO: Add arguments to this component
envMapType = cubeFiles | cubeMap | HDR | preset
envMapPath = file | folderPath | filePath | presetName
presetName options = [ "apartment", "city", "dawn", "forest", "lobby", "night", "park", "studio", "sunset", "warehouse" ]
*/
export default function EnvironmentMap() {
    const loadingManager= new THREE.LoadingManager(
        //loader
        ()=>{
            console.log("loader");
            
        },
        //progress
        ()=>{
          console.log("progress");
          
        }
      )
      const gltfLoader= new GLTFLoader(loadingManager);
      const cubeTextureLoader= new THREE.CubeTextureLoader(loadingManager);
    return (
        <>
            <Environment
                background
                files={[
                    './EnvMapWorld/environmentMaps/2/px.jpg',
                    './EnvMapWorld/environmentMaps/2/nx.jpg',
                    './EnvMapWorld/environmentMaps/2/py.jpg',
                    './EnvMapWorld/environmentMaps/2/ny.jpg',
                    './EnvMapWorld/environmentMaps/2/pz.jpg',
                    './EnvMapWorld/environmentMaps/2/nz.jpg'
                ]}            
            />
        </>
    )
}
