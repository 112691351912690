import React from "react"
import { Header } from "../Components/Header"
import Footer from '../Components/Footer/index'

export const Home = () => {
    return (
        <div>
            <div>
                <Header />
                <div className="onCanvas">
                    This is Home
                </div>
            </div>

            <div className="onFooter">
                <Footer />
            </div>
        </div>
    )
}