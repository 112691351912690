import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { gsap } from 'gsap'

export default function King({ hovered, ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/models/King.gltf')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    const anim = hovered ? 'Wave' : "Idle"
    actions[anim].reset().fadeIn(0.5).play()
    return () => actions[anim].fadeOut(0.5)
  }, [hovered])
  // useEffect(() => {
  //   gsap.to(group.current.position, {
  //     duration: 0.2,
  //     x: Math.random() * 0.1 - 0.05,
  //     y: Math.random() * 0.1 - 0.05,
  //     z: Math.random() * 0.1 - 0.05,
  //     repeat: 4,
  //     yoyo: true,
  //   });
  // }, [hovered])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="CharacterArmature">
          <primitive object={nodes.Root} />
          <group name="King_Body">
            <skinnedMesh name="Cube043" geometry={nodes.Cube043.geometry} material={materials.Skin} skeleton={nodes.Cube043.skeleton} />
            <skinnedMesh name="Cube043_1" geometry={nodes.Cube043_1.geometry} material={materials.Blue} skeleton={nodes.Cube043_1.skeleton} />
            <skinnedMesh name="Cube043_2" geometry={nodes.Cube043_2.geometry} material={materials.Metal} skeleton={nodes.Cube043_2.skeleton} />
            <skinnedMesh name="Cube043_3" geometry={nodes.Cube043_3.geometry} material={materials.Beige} skeleton={nodes.Cube043_3.skeleton} />
            <skinnedMesh name="Cube043_4" geometry={nodes.Cube043_4.geometry} material={materials.Metal_Dark} skeleton={nodes.Cube043_4.skeleton} />
          </group>
          <skinnedMesh name="King_Feet" geometry={nodes.King_Feet.geometry} material={materials.Metal} skeleton={nodes.King_Feet.skeleton} />
          <group name="King_Head">
            <skinnedMesh name="Cube025" geometry={nodes.Cube025.geometry} material={materials.Skin} skeleton={nodes.Cube025.skeleton} />
            <skinnedMesh name="Cube025_1" geometry={nodes.Cube025_1.geometry} material={materials.Hair_White} skeleton={nodes.Cube025_1.skeleton} />
            <skinnedMesh name="Cube025_2" geometry={nodes.Cube025_2.geometry} material={materials.Gold} skeleton={nodes.Cube025_2.skeleton} />
            <skinnedMesh name="Cube025_3" geometry={nodes.Cube025_3.geometry} material={materials.Eye} skeleton={nodes.Cube025_3.skeleton} />
          </group>
          <group name="King_Legs">
            <skinnedMesh name="Cube051" geometry={nodes.Cube051.geometry} material={materials.DarkBrown} skeleton={nodes.Cube051.skeleton} />
            <skinnedMesh name="Cube051_1" geometry={nodes.Cube051_1.geometry} material={materials.Metal} skeleton={nodes.Cube051_1.skeleton} />
            <skinnedMesh name="Cube051_2" geometry={nodes.Cube051_2.geometry} material={materials.Metal_Dark} skeleton={nodes.Cube051_2.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/King.gltf')
