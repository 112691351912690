import { useFrame } from "@react-three/fiber"
import { useRef, useEffect, useState } from "react"
import { TransformControls, OrbitControls, Environment } from "@react-three/drei"
import * as THREE from 'three'


import { useControls } from 'leva'

import EnvironmentMap from "../../Components/EnvironmentMap/index"

export default function EnvMapExperience() {

    const sphereControls = useControls({
        position: {
            // value: -2,
            value: { x: -2, y: 0 },
            // min: -4,
            // max : 4,
            step: 0.01,
            joystick: 'invertY'
        }
    })
    const { envMapIntensity } = useControls('Environment Map', {
        envMapIntensity: { value: 3, min: 0, max: 100, step: 0.01 }
    })




    const cubeRef = useRef()
    const groupRef = useRef()
    const sphereRef = useRef()
    const clickableCubeRef = useRef()

    const audioUrl = './PortalWorld/portal/music/music.mp3';

    useFrame((state, delta) => {
        // // rotate the camera around teh object
        // const angle=state.clock.elapsedTime
        // state.camera.position.x = Math.sin(angle)*8
        // state.camera.position.z = Math.cos(angle)*8
        // state.camera.lookAt(0,0,0)


        cubeRef.current.rotation.y += .005;
        // groupRef.current.rotation.y+=delta

    })
    const handleClick = () => {
        // Navigate to the specified URL when the object is clicked
        // window.open(url, '_self');
        console.log("click");
    };


    const changeCubeColor = () => {
        console.log("event occure", clickableCubeRef.current)
        clickableCubeRef.current.material.color.set(`hsl(${Math.random() * 360},100%,75%)`)
    }
    // const audioRef = useRef(null);
    // const [isPlaying, setIsPlaying] = useState(true);

    // useEffect(() => {
    //     // Create an audio element for the background music
    //     const audio = new Audio('./PortalWorld/portal/music/music.mp3');
    //     audio.loop = true;
    //     audio.volume = 0.3;
    //     audioRef.current=audio

    //     // Play the background music when the component mounts
    //     audio.play();

    //     // Pause the background music when the component unmounts
    //     return () => audio.pause();
    // }, []);
    // const { Music } =useControls('Audio',{ 
    //     Music: { value: isPlaying,
    //          toggle: () => setIsPlaying(!isPlaying) 
    //         } });

    //     useEffect(() => {
    //         // Pause or play the background music based on the Music slider value
    //         if (audioRef.current) {
    //           if (Music === false) {
    //             audioRef.current.pause();
    //           } else {
    //             audioRef.current.play();
    //           }
    //         }
    //       }, [Music]);

    return (
        <>
            <EnvironmentMap />
            {/* <Environment
                background
                files={[
                    './EnvMapWorld/environmentMaps/1/px.jpg',
                    './EnvMapWorld/environmentMaps/1/nx.jpg',
                    './EnvMapWorld/environmentMaps/1/py.jpg',
                    './EnvMapWorld/environmentMaps/1/ny.jpg',
                    './EnvMapWorld/environmentMaps/1/pz.jpg',
                    './EnvMapWorld/environmentMaps/1/nz.jpg'
                ]}
            // preset="apartment"
            /> */}
            {/* <mesh>
                <torusKnotGeometry />
                <meshNormalMaterial />
            </mesh> */}

            <OrbitControls makeDefault />
            {/* <directionalLight position={[ 2, 4, 1 ]} intensity={.8}/> */}
            {/* <ambientLight intensity={0.2} /> */}
            {/* <pointLight position={[2, 4,-1]}/> */}


            <group ref={groupRef}>
                <mesh position={[sphereControls.position.x, sphereControls.position.y, 0]} scale={.75} ref={sphereRef}

                    onClick={handleClick}
                    onPointerEnter={() => document.body.style.cursor = 'pointer'}
                    onPointerLeave={() => document.body.style.cursor = 'default'}

                >
                    <sphereGeometry />
                    <meshStandardMaterial color={"red"} envMapIntensity={envMapIntensity} />
                </mesh>
                <mesh ref={cubeRef} position-x={2} rotation-y={Math.PI * 0.25}
                    onClick={(event) => event.stopPropagation()}
                    onPointerEnter={() => document.body.style.cursor = 'default'}
                >
                    <boxGeometry scale={2} />
                    <meshStandardMaterial color={"purple"} envMapIntensity={envMapIntensity} />
                </mesh>
            </group>
            <TransformControls object={cubeRef} />
            <mesh position-y={-2} rotation-x={-Math.PI * 0.4} scale={10} side={THREE.DoubleSide} >
                <planeGeometry />
                <meshStandardMaterial color={"yellow"} envMapIntensity={envMapIntensity} />
            </mesh>

            <mesh ref={clickableCubeRef} position-x={4}
                rotation-y={Math.PI * 0.25}
                onClick={changeCubeColor}
                onPointerEnter={() => document.body.style.cursor = 'pointer'}
                onPointerLeave={() => document.body.style.cursor = 'default'}
            >
                <boxGeometry scale={2} />
                <meshStandardMaterial color={"purple"} envMapIntensity={envMapIntensity} />
            </mesh>

        </>
    )
}