import { Header } from "../../Components/Header";
import { Canvas } from "@react-three/fiber";
import HospitalWorldExperience from "./HospitalWorldExperience";
import { PerspectiveCamera, KeyboardControls } from "@react-three/drei";

export const HospitalWorld = () => {
    return <div>
        <Header />
        <div className="ovCanvas">
            <KeyboardControls
                map={[
                    { name: "forward", keys: ["ArrowUp", "w", "W"] },
                    { name: "backward", keys: ["ArrowDown", "s", "S"] },
                    { name: "left", keys: ["ArrowLeft", "a", "A"] },
                    { name: "right", keys: ["ArrowRight", "d", "D"] },
                    { name: "jump", keys: ["Space"] },
                ]}>
                <Canvas
                    flat
                    camera={{
                        fov: 45,
                        near: 0.1,
                        far: 20,
                        position: [0, -1, 10],
                        // rotation: [0, Math.PI / 10, 0]
                    }}
                >
                    {/* <PerspectiveCamera */}
                    {/* <PerspectiveCamera makeDefault position={[0, 0, 10]} /> */}
                    <color args={['ivory']} attach="background" />
                    <HospitalWorldExperience />
                </Canvas>
            </KeyboardControls>
        </div>
    </div>;
};
