import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { gsap } from 'gsap'

export default function Swat({ hovered, ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/models/Swat.gltf')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    const anim = hovered ? 'Wave' : "Idle"
    actions[anim].reset().fadeIn(0.5).play()
    return () => actions[anim].fadeOut(0.5)
  }, [hovered])

  // useEffect(() => {
  //   gsap.to(group.current.position, {
  //     duration: 0.2,
  //     x: Math.random() * 0.1 - 0.05,
  //     y: Math.random() * 0.1 - 0.05,
  //     z: Math.random() * 0.1 - 0.05,
  //     repeat: 4,
  //     yoyo: true,
  //   });
  // }, [hovered])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="CharacterArmature">
          <primitive object={nodes.Root} />
          <group name="Pistol">
            <skinnedMesh name="Cube204" geometry={nodes.Cube204.geometry} material={materials.DarkBrown} skeleton={nodes.Cube204.skeleton} />
            <skinnedMesh name="Cube204_1" geometry={nodes.Cube204_1.geometry} material={materials.Grey} skeleton={nodes.Cube204_1.skeleton} />
            <skinnedMesh name="Cube204_2" geometry={nodes.Cube204_2.geometry} material={materials.Black} skeleton={nodes.Cube204_2.skeleton} />
          </group>
          <group name="Swat_Body">
            <skinnedMesh name="Cube018" geometry={nodes.Cube018.geometry} material={materials.Skin} skeleton={nodes.Cube018.skeleton} />
            <skinnedMesh name="Cube018_1" geometry={nodes.Cube018_1.geometry} material={materials.Swat} skeleton={nodes.Cube018_1.skeleton} />
            <skinnedMesh name="Cube018_2" geometry={nodes.Cube018_2.geometry} material={materials.Swat_Black} skeleton={nodes.Cube018_2.skeleton} />
          </group>
          <skinnedMesh name="Swat_Feet" geometry={nodes.Swat_Feet.geometry} material={materials.Swat_Black} skeleton={nodes.Swat_Feet.skeleton} />
          <group name="Swat_Head">
            <skinnedMesh name="Cube037" geometry={nodes.Cube037.geometry} material={materials.Swat_Black} skeleton={nodes.Cube037.skeleton} />
            <skinnedMesh name="Cube037_1" geometry={nodes.Cube037_1.geometry} material={materials.Swat} skeleton={nodes.Cube037_1.skeleton} />
            <skinnedMesh name="Cube037_2" geometry={nodes.Cube037_2.geometry} material={materials.Visor} skeleton={nodes.Cube037_2.skeleton} />
          </group>
          <group name="Swat_Legs">
            <skinnedMesh name="Cube023" geometry={nodes.Cube023.geometry} material={materials.Swat} skeleton={nodes.Cube023.skeleton} />
            <skinnedMesh name="Cube023_1" geometry={nodes.Cube023_1.geometry} material={materials.Swat_Black} skeleton={nodes.Cube023_1.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Swat.gltf')
