/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 scene.gltf --transform 
Files: scene.gltf [136.79KB] > scene-transformed.glb [2.51MB] (-1737%)
Author: Janis Zeps (https://sketchfab.com/zeps9001)
License: SKETCHFAB Standard (https://sketchfab.com/licenses)
Source: https://sketchfab.com/3d-models/personal-office-fb2319942a6d49fa9adf1bc7f7d3aa84
Title: Personal Office
*/

import { useGLTF } from '@react-three/drei'

import {
  Html
} from "@react-three/drei";
import { useEffect, useState } from "react";

import * as THREE from 'three';
import { PatientCard } from "../../Components/PatientCard";

import PatientVitals from "../../Components/PatientVitals";
import PDFViewerExperience from '../PDFViewer/PDFViewerExperience';
import { useControls } from 'leva'
import { useThree, useFrame} from '@react-three/fiber'
import Loader from '../PDFViewer/Loader';
import React from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { ErrorBoundary } from "react-error-boundary";
import { ProductsFetchingError } from "./ErrorBoundaryComponent";
import { Card, Dropdown } from 'flowbite-react';





export function DoctorOfficeScene(props) {

  const {showBoundary} = useErrorBoundary()

  const { nodes, materials } = useGLTF('/DoctorOffice/DoctorOfficeScene.glb')
  const sceneScale = 12.0

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    const fetchPatientDetails = async () => {
    
    try {
  
    await fetch("https://user2.dev.1verse.live/api/v1/user_manager/views/vm_patients/get_patient_details_for_room/1")
        .then((response) => response.json())
        .then((result) => {
            console.log("result------->", result)
            setData(result);
            setLoading(false);
             //throw new Error("Cannot fetch patient details. Try again after sometime!");

            
        })
        .catch((error) => {
          showBoundary(error);
            console.error('Error fetching data:', error);
            setLoading(false); 
            // throw new Error("Cannot fetch patient details. Try again after sometime!");

        });

      } catch (err) {
        showBoundary(err);
        // throw new Error("Cannot fetch patient details. Try again after sometime!");

      }
      };
      fetchPatientDetails();
}, [])


function PatientDetails() {
  const patientDetailsPosition = useControls('PD Position', {

      position: { x: -4.8, y: -1, z: 3.3 }
      
  })



  const patientDetailsRotation = useControls('PD Rotation', {

    rotation: { x: 0, y: Math.PI/3, z: 0 }
    
})


const pdfPosition = useControls('PDF Position', {

  position: { x: -4.8, y: -1, z: 3.3 }
  
})


const pdfRotation = useControls('PDF Rotation', {

  rotation: { x: 0, y: -Math.PI/3, z: 0 }
  
})

if(data.patient){
  return (
    <>
        {/* <group >
            <mesh position={[patientDetails.position.x, patientDetails.position.y, patientDetails.position.z]}>
                <boxGeometry args={[0.1, 1.5, 2.95]} />
                <meshBasicMaterial color={0xaaaaaa} />

            </mesh>
            <Html transform position={[patientDetails.position.x, patientDetails.position.y, patientDetails.position.z]}
                distanceFactor={1.1}
                rotation={[0, 1.57, 0]}
            >
            </Html>
        </group> */}
        <group scale={12}>

        {/* <Loader isLoading={isLoading} /> */}


<mesh
   position={[patientDetailsPosition.position.x, patientDetailsPosition.position.y, patientDetailsPosition.position.z]}
  //  rotation={[0, Math.PI, 0]}
  rotation={[patientDetailsRotation.rotation.x, patientDetailsRotation.rotation.y, patientDetailsRotation.rotation.z]}
  // onLoadSuccess={onDocumentLoadSuccess}
>
  <planeGeometry args={[4.2, 2.3]} />
  <meshBasicMaterial color={0xffffff} side={THREE.DoubleSide} />

</mesh>

<Html transform  position={[patientDetailsPosition.position.x, patientDetailsPosition.position.y, patientDetailsPosition.position.z]}
  rotation={[patientDetailsRotation.rotation.x, patientDetailsRotation.rotation.y, patientDetailsRotation.rotation.z]}
  // rotation={[0, Math.PI, 0]}
  distanceFactor={1.7}
>

  <div className="flex">
      <div style={{ width: '50%', marginRight: '5px' }}>
          {loading ? (
              <p>Loading...</p>
          ) : (
        
             <PatientCard
                    name={data.patient.first_name + " " + data.patient.last_name}
                    disease={data.patient.primary_ailment}
                    age={data.patient.patient_age}
                    gender={data.patient.gender}
                    language={data.patient.preferred_language}
                    location={data.patient.address}
                    weight={data.patient.weight}
                    height={data.patient.height}
                    style={{ width: '100%', height: '100%' }}
               />
          
            
          )}
      </div>
      <div style={{ width: '50%' }}>
      {loading ? (
              <p>Loading...</p>
          ) : (
         
            <PatientVitals
              name={data.patient.first_name+" "+data.patient.last_name}
              gender={data.patient.gender}
              weight={data.patient.weight}
              height={data.patient.height}
              bloodPressure={data.patient.vitals.bp}
              conditions={data.patient.pre_existing_conditions}
        />
      

          )}

      </div>
  </div>
</Html>
{/* <group >
          <mesh
              position={[pdfPosition.position.x, pdfPosition.position.y, pdfPosition.position.z]}
              rotation={[pdfRotation.rotation.x, pdfRotation.rotation.y, pdfRotation.rotation.z]}
          >
              <planeGeometry args={[4.2, 2.3]} />
              <meshBasicMaterial color={0xffffff} side={THREE.DoubleSide} />

          </mesh>


          <Html transform position={[pdfPosition.position.x, pdfPosition.position.y, pdfPosition.position.z]}
              rotation={[pdfRotation.rotation.x, pdfRotation.rotation.y, pdfRotation.rotation.z]}
              distanceFactor={1.7}
          >
              <div style={{ width: '800px', height: '500px', overflowY: 'scroll' }}>
                  <PDFViewerExperience />
              </div>
              <div className="flex">
                  <div style={{ width: '50%', marginRight: '5px' }}>

                  </div>
                  <div style={{ width: '50%' }}>

                  </div>
              </div>
          </Html>
      </group> */}

</group>
    </>
)
}else{
  const { error, resetErrorBoundary } = props;

  return (
    <>
        {/* <group >
            <mesh position={[patientDetails.position.x, patientDetails.position.y, patientDetails.position.z]}>
                <boxGeometry args={[0.1, 1.5, 2.95]} />
                <meshBasicMaterial color={0xaaaaaa} />

            </mesh>
            <Html transform position={[patientDetails.position.x, patientDetails.position.y, patientDetails.position.z]}
                distanceFactor={1.1}
                rotation={[0, 1.57, 0]}
            >
            </Html>
        </group> */}
        <group scale={12}>

        {/* <Loader isLoading={isLoading} /> */}


<mesh
   position={[patientDetailsPosition.position.x, patientDetailsPosition.position.y, patientDetailsPosition.position.z]}
  //  rotation={[0, Math.PI, 0]}
  rotation={[patientDetailsRotation.rotation.x, patientDetailsRotation.rotation.y, patientDetailsRotation.rotation.z]}
  // onLoadSuccess={onDocumentLoadSuccess}
>
  <planeGeometry args={[4.2, 2.3]} />
  <meshBasicMaterial color={0xffffff} side={THREE.DoubleSide} />

</mesh>

<Html transform  position={[patientDetailsPosition.position.x, patientDetailsPosition.position.y, patientDetailsPosition.position.z]}
  rotation={[patientDetailsRotation.rotation.x, patientDetailsRotation.rotation.y, patientDetailsRotation.rotation.z]}
  // rotation={[0, Math.PI, 0]}
  distanceFactor={1.7}
>
<Card>
  <div className="flex">
      <div style={{ width: '50%', marginRight: '5px' }}>
      <div id="container">
    <div className="error-handling" >
      <div className="text-center">
        <h1 className="text-5xl font-semibold text-red-600 mb-4">The world crashed! 🌍</h1>
        <p className="text-2xl text-gray-300 font-semibold">Test error message </p>
        <button className="mt-4" onClick={resetErrorBoundary}>
          Reload Page
        </button>
      </div>
    </div>
    </div>
      </div>
  </div>
  </Card>
</Html>
{/* <group >
          <mesh
              position={[pdfPosition.position.x, pdfPosition.position.y, pdfPosition.position.z]}
              rotation={[pdfRotation.rotation.x, pdfRotation.rotation.y, pdfRotation.rotation.z]}
          >
              <planeGeometry args={[4.2, 2.3]} />
              <meshBasicMaterial color={0xffffff} side={THREE.DoubleSide} />

          </mesh>


          <Html transform position={[pdfPosition.position.x, pdfPosition.position.y, pdfPosition.position.z]}
              rotation={[pdfRotation.rotation.x, pdfRotation.rotation.y, pdfRotation.rotation.z]}
              distanceFactor={1.7}
          >
              <div style={{ width: '800px', height: '500px', overflowY: 'scroll' }}>
                  <PDFViewerExperience />
              </div>
              <div className="flex">
                  <div style={{ width: '50%', marginRight: '5px' }}>

                  </div>
                  <div style={{ width: '50%' }}>

                  </div>
              </div>
          </Html>
      </group> */}

</group>
    </>
)

}

  
}



  return <>  
  <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube002_furniture_0.geometry} material={materials.furniture} position={[-0.168 * sceneScale, 1.645 * sceneScale, -0.01 * sceneScale]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={sceneScale} />
      <mesh geometry={nodes.Cube053_furniture_0.geometry} material={materials.furniture} position={[-2.101 * sceneScale, -0.011 * sceneScale, -0.323 * sceneScale]} rotation={[-Math.PI / 2, 0, 1.145]} scale={1.082 * sceneScale} />
      <mesh geometry={nodes.paintings_bookcase_0.geometry} material={materials.bookcase} position={[-2.085 * sceneScale, 3.272 * sceneScale, 0 * sceneScale]} rotation={[-Math.PI / 2, 0, 0]} scale={sceneScale} />
      <mesh geometry={nodes.Cube004_details_0.geometry} material={materials.details} position={[-2.76 * sceneScale, 1.354 * sceneScale, 0 * sceneScale]} rotation={[-Math.PI / 2, 0, 0]} scale={sceneScale} />
      <mesh geometry={nodes.walls_wall_0.geometry} material={materials.wall} position={[0.62 * sceneScale, 0 * sceneScale, 0.016 * sceneScale]} rotation={[-Math.PI / 2, 0, 0]} scale={sceneScale} />
    </group>

    <ErrorBoundary FallbackComponent={PatientDetails}
              onError={() => console.log("Error happened!")} >
  <PatientDetails />
  </ErrorBoundary>

  
    
    
    
    </>

}

useGLTF.preload('/DoctorOffice/DoctorOfficeScene.glb')
