import {
    Box,
    CubeCamera,
    Environment,
    OrbitControls,
    Sphere,
    useEnvironment,
  } from "@react-three/drei";
  import { Canvas, useFrame } from "@react-three/fiber";
  import { useControls } from "leva";
  import { ReactNode, useRef } from "react";
  import { Group } from "three";
  

  export default function EnvMapSphereExperience(){
    function Rotator({ children }) {
        const groupRef = useRef(new Group());
      
        useFrame(() => {
          groupRef.current.children.forEach((child) => {
            if (child.isObject3D) {
              child.rotateX(0.02);
              child.rotateY(0.02);
            }
          });
        });
      
        return <group ref={groupRef}>{children}</group>;
      }
      
      function ReflectiveSphere() {
        const tweakableProperties = useControls({
          roughness: { value: 0.1, min: 0, max: 1 },
          metalness: { value: 1, min: 0, max: 1 },
        });
      
        return (
          <Sphere args={[1, 256, 256]}>
            <meshStandardMaterial {...tweakableProperties} />
          </Sphere>
        );
      }
      
      function ThreeScene() {
      
        return (
          <>
            <ambientLight />
            <pointLight position={[5, 5, 5]} />
            <pointLight position={[-3, -3, 2]} />
            <OrbitControls />
      
            <Environment
                background
                files={[
                    './EnvMapSphere/modernBuildings/px.png',
                    './EnvMapSphere/modernBuildings/nx.png',
                    './EnvMapSphere/modernBuildings/py.png',
                    './EnvMapSphere/modernBuildings/ny.png',
                    './EnvMapSphere/modernBuildings/pz.png',
                    './EnvMapSphere/modernBuildings/nz.png'
                ]}
    // preset="apartment"
            />
      
            <CubeCamera frames={Infinity}>
              {/* @ts-ignore */}
              {(texture) => (
                <>
                  <Environment map={texture} />
                  <ReflectiveSphere />
                </>
              )}
            </CubeCamera>
      
            <Rotator>
              <Box position={[0, 0, 5]}>
                <meshStandardMaterial color="red" />
              </Box>
      
              <Box position={[-1, 3, 2]}>
                <meshStandardMaterial color="purple" />
              </Box>
            </Rotator>
          </>
        );
      }

      return <ThreeScene />;

  }
  
  