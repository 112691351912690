import { Header } from "../../Components/Header";
import { Canvas } from "@react-three/fiber";

import SvgExtrudeExperience from "./SvgExtrusionExperience";

export const SvgExtrusionWorld = () => {
  return <div>
  <Header />
  <div id="env-map-canvas" className="ovCanvas">
  <Canvas
    flat
    // camera={{
    //   fov: 45,
    //   near: 0.1,
    //   far: 50,
    //   position: [10, 20, 60]
    // }}
    
  >
    <color args={['ivory']} attach="background" />
    <SvgExtrudeExperience />
  </Canvas>
  </div>
</div>;
};
