import React from "react"
import { Header } from "../../Components/Header"
import AgoraIOVideoCall from "../../Components/AgoraIO"

export const VideoCallingExample = () => {
    return <div>
        {/* <Header />
        This is Agora SDK with UI Kit. */}
        <AgoraIOVideoCall />
    </div>
}