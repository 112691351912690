import {
    useTexture, MeshPortalMaterial, RoundedBox,
    Text, CameraControls, useCursor, ScrollControls,
    Scroll, Html, OrbitControls, Environment
} from '@react-three/drei'
import * as THREE from 'three'
import { Perf } from 'r3f-perf'
import { useThree, useFrame, useLoader, extend } from '@react-three/fiber'
import { useRef, useState, useEffect, Suspense, useMemo } from 'react'
import { gsap } from 'gsap'
import { Water } from 'three-stdlib'
import { useControls } from 'leva'
import { useSpring as useSpringThree, animated } from '@react-spring/three';


import King from '../../Components/King/King'
import Adventurer from '../../Components/Adventurer/Adventurer'
import Swat from '../../Components/Swat/Swat'

import Card from './Card'

const ContentDoorStage = ({ children, texture, name, color,
    active, roundBoxRef, setActive, hovered, setHovered,
    effect, setEffect, worldUrl, ...props
}) => {
    const map = useTexture(texture)
    map.flipX = false
    const portalMaterial = useRef()
    const meshRef = useRef();

    const hoverAnimation = useSpringThree({
        scale: hovered === name ? 1.1 : 1,
    });
    // useFrame((_state, delta) => {
    //     const worldOpen = active === name
    //     easing.damp(portalMaterial.current, "blend", worldOpen ? 1 : 0, 0.2, delta)
    // })
    return (
        <>
            <animated.group {...props} scale={hoverAnimation.scale}>
                {/* <Card title={name} description="Card description" imageUrl={texture} /> */}
                <Text fontSize={0.2}
                    position={[0, -1.3, 0.051]}
                    anchorY={'bottom'}>
                    {name}
                    <meshBasicMaterial color={color} toneMapped={false} />
                </Text>
                <RoundedBox args={[2, 3, 0.1]}
                    active={active}
                    name={name}
                    ref={roundBoxRef}
                    onDoubleClick={(e) => (setActive(active === name ? null : name))}
                    // onDoubleClick={changePortal(worldUrl)}
                    onPointerEnter={() => { setHovered(name); setEffect(effect === name ? null : name) }}
                    onPointerLeave={() => { setHovered(null); setEffect(null) }}
                >
                    {/* <planeGeometry args={[2, 3]} /> */}
                    <MeshPortalMaterial side={THREE.DoubleSide}
                        blend={active === name ? 1 : 0}
                        ref={portalMaterial}
                    // onDoubleClick={handlePortalClick}
                    >
                        <ambientLight intensity={1} />
                        {/* <Environment preset='sunset' /> */}
                        {/* here we can load 3d models or any world */}
                        {children}
                        <mesh ref={meshRef}>
                            <sphereGeometry args={[10, 64, 64]} />
                            <meshStandardMaterial map={map} side={THREE.BackSide} />
                        </mesh>
                    </MeshPortalMaterial>
                </RoundedBox>
            </animated.group>
        </>

    )
};
extend({ Water })

const myVector = new THREE.Vector3(4, 4, 2);

const ContentDoorStages = ({ cameraControlsRef, sdoorHeight, myVector, ...props }) => {
    const [active, setActive] = useState(null)
    const [hovered, setHovered] = useState(null)
    const [effect, setEffect] = useState(null)

    const roundBoxRef = useRef()
    const contentDoorStageRef = useRef();

    useCursor(hovered)
    const { scene, camera, gl } = useThree()

    // const handleMouseWheel = (event) => {
    //     event.preventDefault();
    //     console.log("tested")
    //     if (contentDoorStageRef.current.children.length > 3) {
    //         const scrollSpeed = 0.5; // Adjust this value to control the scrolling speed
    //         setHorizontalOffset((prevOffset) => prevOffset - event.deltaX * scrollSpeed);
    //         const scrollDirection = -Math.sign(event.deltaX);
    //         console.log("event", scrollDirection)
    //     }
    // };
    // const initialHorizontalOffset = -157;
    // useEffect(() => {
    //     // Set the initial horizontal offset when the page loads
    //     // setHorizontalOffset(initialHorizontalOffset);
    //     // Add mouse wheel event listener on component mount
    //     window.addEventListener('wheel', handleMouseWheel, { passive: true });
    //     // Clean up the event listener on component unmount
    //     return () => {
    //         window.removeEventListener('wheel', handleMouseWheel);
    //     };
    // }, []);

    // useEffect(() => {
    //     if (active) {
    //         console.log("active", active)
    //         const targetPosition = new THREE.Vector3();
    //         scene.getObjectByName(active).getWorldPosition(targetPosition)
    //         cameraControlsRef.current.setLookAt(
    //             0, 0, 5,
    //             targetPosition.x,
    //             targetPosition.y,
    //             targetPosition.z
    //         )
    //     } else {
    //         cameraControlsRef.current.setLookAt(
    //             0, 0, 10,
    //             0, 0, 0,
    //         )
    //     }
    // }, [active])

    return (
        <ScrollControls
            horizontal
            damping={0.5}
            pages={2}
            distance={2}
        // infinite={true}
        // onScroll={handleMouseWheel}
        >
            <Scroll>
                <Suspense fallback={null}>
                    <group ref={contentDoorStageRef}
                        // position-y={sdoorHeight}
                        position={[0, 1.5, 0]}
                        // scale={myVector}
                        rotation-x={Math.PI / 36}
                    >
                        <ContentDoorStage texture={
                            './Texture/interior_views_galaxy.jpg'
                        } name={'King'} color={'#063240'}
                            active={active}
                            roundBoxRef={roundBoxRef}
                            setActive={setActive}
                            effect={effect} setEffect={setEffect}
                            hovered={hovered}
                            setHovered={setHovered}
                            position-x={0}
                            position-z={-2}
                            worldUrl={'http://localhost:3000/landscape'}
                        >
                            <King position-y={-1.3} hovered={hovered === 'King'} />
                        </ContentDoorStage>
                        <ContentDoorStage texture={
                            './Texture/scifi_city.jpg'
                        } position-x={2.5}
                            position-z={-2}
                            // rotation-y={Math.PI / 6}
                            name={'Adventure'} color={'#a62d0f'}
                            active={active} setActive={setActive}
                            effect={effect} setEffect={setEffect}
                            hovered={hovered} setHovered={setHovered}
                            roundBoxRef={roundBoxRef}
                        >
                            <Adventurer position-y={-1.3} hovered={hovered === 'Adventure'} />
                        </ContentDoorStage>
                        <ContentDoorStage texture={
                            './Texture/scifi_water_world.jpg'
                        }
                            position-x={5}
                            position-z={-2}
                            name={'Swat'} color={'#f2d9bf'}
                            active={active} setActive={setActive}
                            effect={effect} setEffect={setEffect}
                            roundBoxRef={roundBoxRef}
                            hovered={hovered} setHovered={setHovered}
                        >
                            <Swat position-y={-1.3} hovered={hovered === 'Swat'} />
                        </ContentDoorStage>
                        <ContentDoorStage texture={
                            './Texture/scifi_water_world.jpg'
                        } position-x={7.5}
                            position-z={-2}
                            name={'Swat1'} color={'#f2d9bf'}
                            active={active} setActive={setActive}
                            effect={effect} setEffect={setEffect}
                            roundBoxRef={roundBoxRef}
                            hovered={hovered} setHovered={setHovered}

                        >
                            {/* <Swat position-y={-1.3} hovered={hovered === 'Swat'} /> */}
                        </ContentDoorStage>
                        <ContentDoorStage texture={
                            './Texture/scifi_water_world.jpg'
                        }
                            position-x={10}
                            position-z={-2}
                            name={'Swat2'} color={'#f2d9bf'}
                            active={active} setActive={setActive}
                            effect={effect} setEffect={setEffect}
                            roundBoxRef={roundBoxRef}
                            hovered={hovered} setHovered={setHovered}
                        >
                            {/* <Swat position-y={-1.3} hovered={hovered === 'Swat'} /> */}
                        </ContentDoorStage>
                        <ContentDoorStage texture={
                            './Texture/scifi_water_world.jpg'
                        }
                            position-x={12.5}
                            position-z={-2}
                            name={'Swat3'} color={'#f2d9bf'}
                            active={active} setActive={setActive}
                            effect={effect} setEffect={setEffect}
                            roundBoxRef={roundBoxRef}
                            hovered={hovered} setHovered={setHovered}
                        >
                            {/* <Swat position-y={-1.3} hovered={hovered === 'Swat'} /> */}
                        </ContentDoorStage>
                    </group>
                </Suspense>
            </Scroll>
        </ScrollControls>
    )
}


export default function ContentDoorExperience() {
    const cameraControlsRef = useRef();

    function Ocean() {

        // const [distortion, setDistortion] = useState({});
        const [color, setColor] = useState('#ffffff');
        const [position, setPosition] = useState({ x: 0, y: 0, z: 0 });


        const { oceanDistortionScale, groundRadius } = useControls({
            oceanDistortionScale: {
                value: 3.7,
                min: 0,
                max: 25,
                step: 0.1
            },
            groundRadius: {
                value: 100,
                min: 0,
                max: 10000,
                step: 1.0,
            }
        })

        const { OceanColor } = useControls({
            OceanColor: {
                value: color
            },
            onselect: () => setColor(color)
        })

        const { oceanPosition } = useControls({
            oceanPosition: {
                value: {
                    x: position.x,
                    y: position.y,
                    z: position.z
                }
            },
            onchange: () => setPosition(position)
        })


        const ref = useRef()
        const gl = useThree((state) => state.gl)
        const waterNormals = useLoader(THREE.TextureLoader, './EnvGround/waternormals.jpeg')
        waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping
        const geom = useMemo(() => new THREE.CircleGeometry(10000, 10000), [])
        const config = useMemo(
            () => ({
                textureWidth: 512,
                textureHeight: 512,
                waterNormals,
                sunDirection: new THREE.Vector3(),
                waterColor: OceanColor,
                distortionScale: oceanDistortionScale,
                position: oceanPosition,
                format: gl.encoding
            }),
            [waterNormals, OceanColor, oceanPosition]

        )

        console.log("oceanPosition", oceanPosition)



        useFrame((state, delta) => (ref.current.material.uniforms.time.value += delta))
        return <water ref={ref} args={[geom, config]} rotation-x={-Math.PI / 2} />
    }


    const [doorHeight, setDoorHeight] = useState()

    const { sdoorHeight } = useControls({
        sdoorHeight: {
            value: 5.7,
            min: 0,
            max: 100,
            step: 0.01
        },
        onchange: () => setDoorHeight(sdoorHeight)
    });

    return <>
        {/* <CameraControls ref={cameraControlsRef}
            minDistance={10} // Set your desired maximum zoom-in level here
            maxDistance={10}

        /> */}
        {/* <OrbitControls ref={cameraControlsRef} enableZoom={false} /> */}
        {/* <ambientLight intensity={0.5} /> */}
        {/* <mesh position={[0, 0, -10]} >
            <boxGeometry scale={2} />
            <meshStandardMaterial color={"yellow"} />
        </mesh> */}
        <ContentDoorStages cameraControlsRef={cameraControlsRef} sdoorHeight={sdoorHeight}
            myVector={myVector}
        />

        <Environment
            background
            files={"./EnvGround/dark_sky.hdr"}
        />

        <Perf position="top-left" />

        <OrbitControls makeDefault enableZoom={false} />

        <directionalLight castShadow position={[1, 2, 3]} intensity={1.5} />
        <ambientLight intensity={0.5} />

        <mesh receiveShadow {...<Ocean />}>
            {/* <cylinderGeometry args={[5.5, 5.5, 0.1, 32]}/> */}
            <meshStandardMaterial color="black" />
            <pointLight position={[100, 100, 100]} />
            <pointLight position={[-100, -100, -100]} />
            <Suspense fallback={null}>
                <Ocean />
            </Suspense>
        </mesh>

    </>
}
