import React from "react";
import { Header } from "../../Components/Header";
import { Canvas } from "@react-three/fiber";
import EnvMapSphereExperience from "./EnvMapSphereExperience";

export const EnvMapSphere = () => {
  return <div>
  <Header />
  <div id="env-map-canvas" className="ovCanvas">
  <Canvas
    flat
    camera={{
      fov: 45,
      near: 0.1,
      far: 50,
      position: [1, 2, 6]
    }}
  >
    <color args={['ivory']} attach="background" />
    <EnvMapSphereExperience />
  </Canvas>
  </div>
</div>;
};
