// import React, { useEffect } from 'react';
// import { useThree, useLoader, useFrame } from '@react-three/fiber';
// import * as THREE from 'three';
// import { gsap } from 'gsap';
// import { useProgress } from '@react-three/drei';

// const OverlayLoader = () => {
//   const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1);
//   const overlayMaterial = new THREE.ShaderMaterial({
//     transparent: true,
//     uniforms: {
//       uAlpha: { value: 1 },
//     },
//     vertexShader: `
//       void main() {
//         gl_Position = vec4(position, 1.0);
//       }
//     `,
//     fragmentShader: `
//       uniform float uAlpha;
//       void main() {
//         gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
//       }
//     `,
//   });

//   const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial);
//   const { scene } = useThree();

//   useEffect(() => {
//     scene.add(overlay);

//     // Cleanup function to remove the overlay when the component unmounts
//     return () => {
//       scene.remove(overlay);
//     };
//   }, [scene, overlay]);

//   // Progress handling
//   const isReady = useProgress((state) => {
//     if (state.progress === 100) {
//       gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 10, value: 0, ease: 'expo.out' });
//     }
//   });

//   // Return the overlay mesh as the JSX component
//   return <primitive object={overlay} />;
// };

// export default OverlayLoader;


import * as THREE from 'three'
import { useProgress } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { gsap } from 'gsap'


export default function OverlayLoader(){
    const overlayGeometry= new THREE.PlaneGeometry(2,2,1,1)
    const overlayMaterial= new THREE.ShaderMaterial({
      transparent:true,
      uniforms:{
        uAlpha:{value:1}
      },
      // wireframe:true,
      vertexShader:`
        void  main(){
          gl_Position=vec4(position,1.0);
        }
      `,
      fragmentShader:`
        uniform float uAlpha;
        void main(){
          gl_FragColor=vec4(0.0,0.0,0.0,uAlpha);
        }
      `
    })
    const overlay= new THREE.Mesh(overlayGeometry,overlayMaterial)
  
    // const { gl } = useThree()
    const isReady = useProgress((state) => {
      console.log("state",state)
      if(state.progress === 100){
        gsap.to(overlayMaterial.uniforms.uAlpha,{duration:10,value:0})
        return 1;
      }});
      console.log("ready",isReady)

      useFrame(({scene}) => {
        // camera.position.set(0, 0, 50); 
        // console.log("datatad",data)
        scene.add(overlay)
    
    
      });
}