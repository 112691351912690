import React from "react";
import { Header } from "../../Components/Header";
import { Canvas } from "@react-three/fiber";
import PortalWorldExperience from "./PortalWorldExperience";

export const PortalWorld = () => {

  return <div>
      <Header />
      <div id="ov-canvas">
      <Canvas
        flat
        camera={{
          fov: 45,
          near: 0.1,
          far: 50,
          position: [1, 2, 6]
        }}
      >
        <color args={['#030202']} attach="background" />
        <PortalWorldExperience />
      </Canvas>
      </div>
    </div>;
};
