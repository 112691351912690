import * as THREE from 'three';
import { useState, useEffect, Suspense } from 'react';
import { SVGLoader as loader } from 'three/examples/jsm/loaders/SVGLoader';
import { Center, OrbitControls, useProgress } from '@react-three/drei';


import  EnvironmentMap  from "../../Components/EnvironmentMap/index"
// import OverlayLoader from '../../Components/OverlayLoader/index';
import OverlayLoader from './OverlayLoader';


const svgPath = '/logo1Verse.svg';

const Loading = () => {
  return (
    <mesh position={[0, 0, 0]} rotation-y={Math.PI * 0.25} scale={[1, 3, 2]}>
      <boxGeometry />
      <meshPhongMaterial color={'purple'} />
    </mesh>
  );
};

const svgResource = new Promise<THREE.Shape[]>((resolve) =>
  new loader().load(svgPath, (data) => {
    const shapes = (data as any).paths.map((path: any) => {
      const shape = new THREE.Shape();
      shape.curves = path.toShapes(true)[0].curves; // Extract curves from the path
      return shape;
    });
    resolve(shapes);
  })
);

function ExtrudedShape({ shape }: { shape: THREE.Shape }) {
  const extrudeSettings = {
    depth: 20,
    bevelEnabled: false,
  };

  return (
    <mesh position={[0, 0, 0]}>
      <extrudeGeometry args={[shape, extrudeSettings]} />
      <meshPhongMaterial attach="material" color={'red'} side={THREE.DoubleSide} />
    </mesh>
  );
}
export default function SvgExtrudeExperience() {
  const [shapes, setShapes] = useState<THREE.Shape[]>([]);
  const [loading, setLoading] = useState(true);

  OverlayLoader()

  useEffect(() => {
    svgResource.then((shapes) => {
      console.log("shapes", shapes);
        

      
      setShapes(shapes);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <ambientLight intensity={0.5} />
      <OrbitControls />
      {/* <Suspense fallback={<OverlayLoader />}> */}
      <EnvironmentMap />
      <Center>
      {/* <mesh position={[0, 0, 0]} rotation-y={Math.PI * 0.25} scale={[1, 3, 2]}>
        <boxGeometry />
        <meshPhongMaterial color={'purple'} />
      </mesh> */}
      {loading ? <Loading /> : shapes.map((shape, index) => <ExtrudedShape key={index} shape={shape} />)}
      </Center>
      {/* </Suspense> */}
    </>
  );
}


// import * as THREE from 'three'
// import { useState, useEffect } from 'react'
// import { flatten } from 'lodash'
// import { SVGLoader as loader} from 'three/examples/jsm/loaders/SVGLoader';
// import { OrbitControls, Svg, Center } from '@react-three/drei';


// const svgPath = '/logo1Verse.svg';
// // console.log("svgPath", svgPath);
// const Loading = () => {
//   return (
//     <>
//       <mesh position={[0,0,0]} rotation-y={Math.PI*0.25} scale={[1,3,2]} >
//                 <boxGeometry  />
//                 <meshPhongMaterial color={"purple"} />           
//         </mesh>
//     </>
//   )
// }
// // const svgResource = new Promise<THREE.ShapeGeometry[]>((resolve) =>
// //   new loader().load(svgPath, (data) => {
// //     console.log("data",data)
// //     const shapes = (data as any).paths.map((path: any) => ({
// //       shape: new THREE.ShapeGeometry(path.toShapes(true)),
// //       color: path.color,
      
// //     }));
// //     // console.log("shapesssss",shapes)
// //     // resolve(shapes)
// //     resolve(flatten(shapes.map((shape:any,index: number,  ) => (
// //       { shape:shape.shape,  index, color:shape.color, }
// //       ))));
// //   })
// // );
// const svgResource = new Promise<ShapeProp[]>((resolve) =>
//   new loader().load(svgPath, (data) => {
//     console.log("SvG data", data)
//     const shapes = (data as any).paths.map((path: any) => {
//       const shape = new THREE.Shape().setFromPoints(path.toShapes()[0].getPoints());
//       const points3D = path.toShapes()[0].getPoints().map((p:any) => new THREE.Vector3(p.x, p.y, 0)); // Convert to Vector3
//       const boundingBox = new THREE.Box3().setFromPoints(points3D);
//       const position = boundingBox.getCenter(new THREE.Vector3());

//       console.log('SVG Shape:', shape);
//       console.log('SVG Points3D:', points3D);
//       console.log('SVG BoundingBox:', boundingBox);
//       console.log('SVG Position:', position);
//       // if (path.userData.transform) {
//       //   position.setFromMatrixPosition(path.userData.transform);
//       // }

//       // if (path.currentPath && path.currentPath.currentPoint) {
//       //   const currentPoint = path.currentPath.currentPoint;
//       //   position.set(currentPoint.x, currentPoint.y, 0); // Assuming z = 0
//       // }

//       return {
//         shape,
//         color: path.color,
//         position: [position.x, position.y, 0],
//       };
//     });

//     resolve(flatten(shapes.map((shape: ShapeProp, index: number) => ({
//       shape: shape.shape,
//       index,
//       color: shape.color,
//       position: shape.position,
//     }))));
//   })
// );
// type ShapeProp = {
//   shape: THREE.ShapeGeometry;
//   // position: [number, number, number];
//   color: { r: number; g: number; b: number };
//   // opacity: number;
//   index: number;
//   position: [number, number, number];
// };
// function Shape({ shape,  color, index,  position }: ShapeProp ) {
//   const rgbColor = `rgb(${color.r*255 }, ${color.g*255 }, ${color.b*255 })`;
//   return (
//     <mesh position={position}>
//       <meshPhongMaterial attach="material" color={rgbColor} side={THREE.DoubleSide} />
//       {/* <shapeBufferGeometry attach="geometry" args={[shape]} /> */}
//       <primitive object={shape} />
//     </mesh>
//   )
// } 
// // svgResource.then((data)=>console.log("svgPthhs",data))
// export default function SvgExtrudeExperience() {
//   const [shapes, setShapes] = useState<ShapeProp[]>([]);
//   const [loading, setLoading] = useState(true); 
//   useEffect(() => {
//     svgResource.then(shapes => {
//       console.log("shapes ", shapes)
//       const shapeProps = shapes.map((shape:any, index) => ({...shape,index})
//       // {

//       //   return({ 
//       //   //   shape: shape.shape,
//       //   // color: { r: shape.color.r, g: shape.color.g, b: shape.color.b }, 
//       //   // position:shape.position,
//       //   ...shape,
//       //   index,

//       //   })
//       // }
      
//       );
//       console.log("shapeProps",shapeProps)
//       setShapes(shapeProps);
//       setLoading(false);
//     });
//   }, []);

//   return (
//     <>
//       <ambientLight intensity={0.5} />
//       {/* <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} /> */}
//       <OrbitControls   />
//       {/* <mesh position-x={2} rotation-y={Math.PI*0.25} scale={[1,3,2]} >
//                 <boxGeometry  />
//                 <meshPhongMaterial color={"purple"} />           
//         </mesh> */}
//       {/* <Center >
//         <Svg src={svgPath}   />
//       </Center> */}
//       {loading ? (
//         <Loading />
//       ) : (

//         // <group position={[1600, -700, 0]} rotation={[0, THREE.MathUtils.degToRad(180), 0]}>
//         //   {shapes.map((shapeProps) => (
//         //     <Shape key={shapeProps.index} {...shapeProps} />
//         //   ))}
//         // </group>
//         // <group  rotation={[0, THREE.MathUtils.degToRad(180), 0]}>
//         //   {shapes.map((shape, index) => (
//         //     <Shape key={index} shape={shape.shape} color={shape.color} index={index} />
//         //   ))}
//         // </group>
//       // shapes.map((item) => <Shape key={item.index} {...item} />)
//       // <group position={[0, 0, 0]}> {/* Adjust the position of the group */}
//       //     {shapes.map(item => (
//       //       <Shape key={item.index} {...item} />
//       //     ))}
//       //   </group>
//       <group>
//       {shapes.map((item) => (
//         <Shape key={item.index} {...item} />
//       ))}
//     </group>
      
//       )}
      
       
//     </>
//   );
// }
