import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { gsap } from 'gsap'

export default function Adventurer({ hovered, ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/models/Adventurer.gltf')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    const anim = hovered ? 'Wave' : "Idle"
    actions[anim].reset().fadeIn(0.5).play()
    return () => actions[anim].fadeOut(0.5)
  }, [hovered])
  // useEffect(() => {
  //   gsap.to(group.current.position, {
  //     duration: 0.2,
  //     x: Math.random() * 0.1 - 0.05,
  //     y: Math.random() * 0.1 - 0.05,
  //     z: Math.random() * 0.1 - 0.05,
  //     repeat: 4,
  //     yoyo: true,
  //   });
  // }, [hovered])
  // useEffect(() => {
  //   const audio = new Audio('./PortalWorld/portal/music/music.mp3');
  //   audio.loop = true;
  //   audio.volume = 0.3;
  //   if (hovered) {
  //     audio.play()
  //   }
  //   return () => audio.pause()
  // }, [hovered])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="CharacterArmature">
          <primitive object={nodes.Root} />
          <group name="Adventurer_Body">
            <skinnedMesh name="Cube063" geometry={nodes.Cube063.geometry} material={materials.Skin} skeleton={nodes.Cube063.skeleton} />
            <skinnedMesh name="Cube063_1" geometry={nodes.Cube063_1.geometry} material={materials.Green} skeleton={nodes.Cube063_1.skeleton} />
            <skinnedMesh name="Cube063_2" geometry={nodes.Cube063_2.geometry} material={materials.LightGreen} skeleton={nodes.Cube063_2.skeleton} />
          </group>
          <group name="Adventurer_Feet">
            <skinnedMesh name="Cube052" geometry={nodes.Cube052.geometry} material={materials.Grey} skeleton={nodes.Cube052.skeleton} />
            <skinnedMesh name="Cube052_1" geometry={nodes.Cube052_1.geometry} material={materials.Black} skeleton={nodes.Cube052_1.skeleton} />
          </group>
          <group name="Adventurer_Head">
            <skinnedMesh name="Cube039" geometry={nodes.Cube039.geometry} material={materials.Skin} skeleton={nodes.Cube039.skeleton} />
            <skinnedMesh name="Cube039_1" geometry={nodes.Cube039_1.geometry} material={materials.Eyebrows} skeleton={nodes.Cube039_1.skeleton} />
            <skinnedMesh name="Cube039_2" geometry={nodes.Cube039_2.geometry} material={materials.Hair} skeleton={nodes.Cube039_2.skeleton} />
            <skinnedMesh name="Cube039_3" geometry={nodes.Cube039_3.geometry} material={materials.Eye} skeleton={nodes.Cube039_3.skeleton} />
          </group>
          <group name="Adventurer_Legs">
            <skinnedMesh name="Cube020" geometry={nodes.Cube020.geometry} material={materials.Brown} skeleton={nodes.Cube020.skeleton} />
            <skinnedMesh name="Cube020_1" geometry={nodes.Cube020_1.geometry} material={materials.Brown2} skeleton={nodes.Cube020_1.skeleton} />
          </group>
          <group name="Backpack">
            <skinnedMesh name="Plane" geometry={nodes.Plane.geometry} material={materials.Brown} skeleton={nodes.Plane.skeleton} />
            <skinnedMesh name="Plane_1" geometry={nodes.Plane_1.geometry} material={materials.LightGreen} skeleton={nodes.Plane_1.skeleton} />
            <skinnedMesh name="Plane_2" geometry={nodes.Plane_2.geometry} material={materials.Gold} skeleton={nodes.Plane_2.skeleton} />
            <skinnedMesh name="Plane_3" geometry={nodes.Plane_3.geometry} material={materials.Green} skeleton={nodes.Plane_3.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Adventurer.gltf')
