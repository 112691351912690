import React from "react";
import { Header } from "../../Components/Header";
import { Canvas } from "@react-three/fiber";
import {DoctorOfficeScene} from "./DoctorOfficeScene";
import * as THREE from "three";
import Character from "../../Components/Character/Character";
import { Html, Loader, OrbitControls } from "@react-three/drei";
import { ErrorBoundary } from "react-error-boundary";
import { ProductsFetchingError } from "./ErrorBoundaryComponent";

export const DoctorOfficeExperience = () => {
  const fov = 60;
  const aspect = 1920 / 1080;
  const near = 1.0;
  const far = 1000.0;
  const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
  camera.position.set(25, 10, 25);

  const hemiLight = new THREE.HemisphereLight(0xffffff, 0xfffffff, 0.6);
  hemiLight.color.setHSL(0.6, 1, 0.6);
  hemiLight.groundColor.setHSL(0.095, 1, 0.75);

  const light = new THREE.DirectionalLight(0xffffff, 1.0);
  light.position.set(-100, 100, 100);
  light.target.position.set(0, 0, 0);
  light.castShadow = true;
  light.shadow.bias = -0.001;
  light.shadow.mapSize.width = 4096;
  light.shadow.mapSize.height = 4096;
  light.shadow.camera.near = 0.1;
  light.shadow.camera.far = 500.0;
  light.shadow.camera.near = 0.5;
  light.shadow.camera.far = 500.0;
  light.shadow.camera.left = 50;
  light.shadow.camera.right = -50;
  light.shadow.camera.top = 50;
  light.shadow.camera.bottom = -50;

  return <div>
  <Header />
  <div className="ovCanvas">
  <Canvas flat camera={camera}>
    {/* <hemisphereLight {...hemiLight} />
    <directionalLight {...light} /> */}
    <ambientLight intensity={0.1} />
    <color args={['ivory']} attach="background" />
    <pointLight intensity={2} position={[100, 50, 100]} rotation={[-Math.PI / 2, 0, 0]} />
    <ErrorBoundary FallbackComponent={ProductsFetchingError}
        onError={() => console.log("Error happened!")} >
    <DoctorOfficeScene />

    </ErrorBoundary>
    
    <OrbitControls />
    {/* <Character camera={camera} /> */}
  </Canvas>
  </div>
</div>;
};
