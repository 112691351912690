import { Canvas } from "@react-three/fiber";

import { Header } from "../../Components/Header";
import ContentDoorExperience from "./ContentDoorExperience";

export const ContentDoor = () => {

    return (
        <>
            <div>
                <Header />
                <div className="ovCanvas">
                    <Canvas
                        flat
                        camera={{
                            fov: 30,
                            near: 0.1,
                            far: 50,
                            position: [0, 3, 10],

                        }}
                    >
                        {/* <color args={['black']} attach="background" /> */}
                        <ContentDoorExperience />
                    </Canvas>
                </div>

            </div>

        </>
    )

}