import { Canvas } from "@react-three/fiber";

import { Header } from "../../Components/Header";
import ExtrudeExperience from "./ExtrudeExperience";


export  const  ExtrudeWorld=() => {
    return(
        <div>
            <Header />
            <div  className="ovCanvas">
                <Canvas
                    flat
                    camera={{
                        fov: 45,
                        near: 0.1,
                        far: 50,
                        position: [1, 2, 6]
                    }}
                >
                <color args={['ivory']} attach="background" />
                <ExtrudeExperience />
                </Canvas>
            </div>
        </div>
    )
    
  }