import React from 'react';
import { Canvas } from '@react-three/fiber';
import { Header } from '../../Components/Header';
import PDFViewerExperience from './PDFViewerExperience';


export const PdfViewer = () => {
  return (
    <div>
      <Header />
      <div className="ovCanvas">
        <Canvas
          flat
          camera={{
            fov: 45,
            near: 0.1,
            far: 50,
            position: [1, 2, 6],
          }}
        >
          <color args={['ivory']} attach="background" />

          <PDFViewerExperience />
        </Canvas>
      </div>
    </div>
  );
};
