'use client';
import { Html } from '@react-three/drei';
import { Card, Dropdown } from 'flowbite-react';
interface PatientCardsProps {
    name: string;
    disease: string;
    age: Number;
    gender: string;
    language: string
    location: string
    weight: string;
    height: string;

}

export function PatientCard({ name, disease, age, gender, language, location, weight, height, }: PatientCardsProps) {
    return (
        <>
            <Card>
                <div className="flex justify-between px-4 pt-4">
                    {/* Left Column */}
                    <div className="flex flex-col items-center flex-8">
                        <img
                            alt="Bonnie image"
                            className="mb-3 rounded-full shadow-lg"
                            height="96"
                            // src="./logo1Verse.svg"
                            src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                            width="96"
                        />
                        <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                            {name}
                        </h5>
                        <span className="text-sm text-gray-500 dark:text-gray-400 text-center">
                            {disease}
                        </span>
                        <div className="grid grid-cols-2 gap-2 mt-2"> {/* Use grid layout */}
                            <div>
                                <p><strong>Gender:</strong> {gender}</p>
                            </div>
                            <div>
                                <p><strong>Weight:</strong> {weight} kg</p>
                            </div>
                            <div>
                                <p><strong>Height:</strong> {height} cm</p>
                            </div>
                            <div>
                                <p><strong>Location:</strong> {location} cm</p>
                            </div>
                        </div>
                        <div className="mt-4 flex-start space-x-3 lg:mt-8">
                            <a
                                className=" inline-flex items-center rounded-lg bg-cyan-700 px-4 py-2 text-center text-sm font-medium text-white hover:bg-cyan-800 focus:outline-none focus:ring-4 focus:ring-cyan-300 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
                            // style={{ padding: '10px 30px' }}
                            // href="#"
                            >
                                <p>
                                    Send Message
                                </p>
                            </a>
                            {/* <a
                            className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-4 py-2 text-center text-sm font-medium text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                            href="#"
                        >
                            <p>
                                Message
                            </p>
                        </a> */}
                        </div>
                    </div>
                    {/* Perpendicular Line */}
                    <div className="border-r border-gray-300 h-96 my-4 mx-6"></div>
                    {/* Right Column */}
                    <div className="flex flex-col items-start ml-6">
                        <div className='grid grid-cols- gap-2 mt-2'>
                            <div>
                                <p><strong>Home Address:</strong> {"address"}</p>
                            </div>
                            <div>
                                <p><strong>Mobile Phone:</strong> {"9891000000"}</p>
                            </div>
                            <div>
                                <p><strong>Work Phone:</strong> {"05425789651"} cm</p>
                            </div>
                            <div>
                                <p><strong>Email:</strong> {"dummy@gmail.com"} cm</p>
                            </div>
                        </div>
                        {/* Add more user details here */}
                    </div>
                </div>
            </Card>
        </>

    )
}