import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Extrude } from '@react-three/drei';
import * as THREE from 'three';



const extrudeSettings = { steps: 1,
                          depth: 2,
                          bevelEnabled: false,
                          // bevelThickness: 1,
                          // bevelSize: 1,
                          // bevelSegments: 5 
                        };
const SIDE = 6;

export default function ExtrudeExperience(props) {
  
  const shape = React.useMemo(() => {
    const _shape = new THREE.Shape();
      _shape.moveTo(0, 0);
      _shape.lineTo(0, 4);
      _shape.lineTo(4, 4);
      _shape.lineTo(4, 0);
      _shape.lineTo(0, 0);

    // _shape.moveTo(0, 1);
    // _shape.lineTo(SIDE, 0);
    // _shape.lineTo(SIDE, SIDE * 1);
    // _shape.lineTo(0, SIDE * 1);
    // _shape.lineTo(0, SIDE * 2);
    // _shape.lineTo(-SIDE, SIDE * 2);
    // _shape.lineTo(-SIDE, SIDE);
    // _shape.lineTo(0, SIDE);

    return _shape;
  }, []);

  console.log("tetris", shape)
  // const extrudeSettings = {
  //   steps: 1,
  //   depth: 1,
  //   bevelEnabled: false,
  // };

  return (
    <>
      <ambientLight intensity={0.5} />
      {/* <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} /> */}
      <OrbitControls autoRotate autoRotateSpeed={5} />
      <Extrude args={[shape, extrudeSettings]}>
        <meshPhysicalMaterial
            flatShading
            color="#3E64FF"
            thickness={SIDE}
            roughness={0.4}
            clearcoat={1}
            clearcoatRoughness={1}
            transmission={0.8}
            ior={1.25}
            attenuationTint="#fff"
            attenuationDistance={0}
          />
        {/* <meshPhysicalMaterial attach="material" color="purple" side={THREE.DoubleSide}/> */}
      </Extrude>
    </>
  );
}
