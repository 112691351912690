'use client';
import { Dropdown, Navbar, Avatar } from 'flowbite-react';


// import React from "react"
// import { Link } from "react-router-dom"
// export const Header = () => {
//     return (
//         <header className="flex item-center justify-between border-solid shadow bg-gray-900 h-16 ">
//             <div className='m-3 text-slate-100 py-2'>Logo</div>
//             <nav className="py-2 fixed top-0 left-48 w-full h-16 bg-gray-800 text-white flex justify-center items-center">
//                 <ul className="flex md:flex-row flex-col md:items-center md:gap-[1vw] gap-10">
//                     <li className='m-3 inline-block'>
//                         <Link to={'/'} className="hover:text-gray-50 text-slate-300">Home</Link>
//                     </li>
//                     <li className='m-3 inline-block'>
//                         <Link to={'/env-map-example'} className="hover:text-gray-50 text-slate-300">Environment Map Example</Link>
//                     </li>
//                     <li className='m-3 inline-block'>
//                         <Link to={'/gt-world'} className="hover:text-gray-50 text-slate-300">GT  World</Link>
//                     </li>
//                     <li className='m-3 inline-block'>
//                         <Link to={'/portal-world'} className="hover:text-gray-50 text-slate-300">Portal World</Link>
//                     </li>
//                     <li className='m-3 inline-block'>
//                         <Link to={'/video-calling-example'} className="hover:text-gray-50 text-slate-300">Video Calling Example</Link>
//                     </li>
//                     <li className='m-3 inline-block'>
//                         <Link to={'/physics-world'} className="hover:text-gray-50 text-slate-300">Physics World</Link>
//                     </li>
//                     <li className='m-3 inline-block'>
//                         <Link to={'/env-map-sphere'}>Sphere Environment Map</Link>
//                     </li>
//                     <li className='m-3 inline-block'>
//                         <Link to={'/world-with-character'}>Character Example</Link>
//                     </li>
//                     <li className='m-3 inline-block'>
//                         <Link to={'/landscape'}>Landscape</Link>
//                     </li>

//                     <li className='m-3 inline-block'>
//                         <Link to={'/walk'}>Walk</Link>
//                     </li>
//                     <li className='m-3 inline-block'>
//                         <Link to={'/extrude-world'}>Extrude World</Link>
//                     </li>
//                     <li className='m-3 inline-block'>
//                         <Link to={'/svg-extrude-world'}>SVG Extrude World</Link>
//                     </li>
//                     <li className='m-3 inline-block'>
//                         <Link to={'/content-door'} className="hover:text-gray-50 text-slate-300">Content Door</Link>
//                     </li>
//                 </ul>
//             </nav>
//             <div></div>
//         </header>
//     )
// }




export function Header() {
    return (
        <Navbar
            fluid
            rounded
        >
            <Navbar.Brand href="/">
                <img
                    alt="One Verse Logo"
                    className="mr-3 h-6 sm:h-9"
                    src="/logo1Verse.svg"
                />
                {/* <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                    Flowbite React
                </span> */}
            </Navbar.Brand>
            <div className="flex md:order-4">
                <Dropdown
                    inline
                    label={<Avatar alt="User settings" img="https://flowbite.com/docs/images/people/profile-picture-5.jpg" rounded />}
                >
                    <Dropdown.Header>
                        <span className="block text-sm">
                            Bonnie Green
                        </span>
                        <span className="block truncate text-sm font-medium">
                            name@flowbite.com
                        </span>
                    </Dropdown.Header>
                    <Navbar.Link href='/extrude-world'>
                        Extrude World
                    </Navbar.Link>
                    <Navbar.Link href='/extrude-world'>
                        Extrude World
                    </Navbar.Link>
                    <Navbar.Link href='/extrude-world'>
                        Extrude World
                    </Navbar.Link>
                    <Dropdown.Divider />
                    <Navbar.Link href='/extrude-world'>
                        Extrude World
                    </Navbar.Link>
                </Dropdown>
                <Navbar.Toggle />
            </div>
            <Navbar.Collapse>
                <Navbar.Link
                    active
                    href='/'
                >
                    {/* <p> */}
                    Home
                    {/* </p> */}
                </Navbar.Link>
                <Navbar.Link href='/content-door'>
                    Lobby World
                </Navbar.Link>
                <Navbar.Link href='/portal-world'>
                    Portal World
                </Navbar.Link>
                <Navbar.Link href='/hospital-world'>
                    Hospital World
                </Navbar.Link>
                <div className="flex md:order-2">
                    <Dropdown inline label={"Examples"}>
                        {/* <Dropdown.Header>
                            Header content
                        </Dropdown.Header> */}
                        <div className="dropdown-item">
                            <Navbar.Link href='/doctoroffice'>
                                Doctor Office
                            </Navbar.Link>
                        </div>
                        <div className="dropdown-item">
                            <Navbar.Link href='/landscape'>
                                Landscape
                            </Navbar.Link>
                        </div>
                        <Dropdown.Divider />
                        <div className="dropdown-item">
                            <Navbar.Link href='/world-with-character'>
                                Character
                            </Navbar.Link>
                        </div>
                        <Dropdown.Divider />
                        <div className="dropdown-item">
                            <Navbar.Link href='/svg-extrude-world'>
                                Extrude World
                            </Navbar.Link>
                        </div>
                        <Dropdown.Divider />
                        <div className="dropdown-item">
                            <Navbar.Link href='/svg-extrude-world'>
                                SVG Extrusion
                            </Navbar.Link>
                        </div>
                        <Dropdown.Divider />
                        <div className="dropdown-item">
                            <Navbar.Link href='/video-calling-example'>
                                Video Calling
                            </Navbar.Link>
                        </div>
                        <Dropdown.Divider />
                        <div className="dropdown-item">
                            <Navbar.Link href='/env-map-sphere'>
                                Sphere Environment Map
                            </Navbar.Link>
                        </div>
                        <Dropdown.Divider />
                        <div className="dropdown-item">
                            <Navbar.Link href='/gt-world'>
                                GT World
                            </Navbar.Link>
                        </div>
                        <Dropdown.Divider />
                        <div className="dropdown-item">
                            <Navbar.Link href='/env-map-example'>
                                Environment Map World
                            </Navbar.Link>
                        </div>
                        <Dropdown.Divider />
                        <div className="dropdown-item">
                            <Navbar.Link href='/physics-world'>
                                Physics World
                            </Navbar.Link>
                        </div>
                        <div className="dropdown-item">
                            <Navbar.Link href='/view-pdf'>
                                PDF Viewer
                            </ Navbar.Link>
                            <Navbar.Link href='/multiplayer-game'>
                                MultiPlayer Game
                            </Navbar.Link>
                        </div>
                        <div className="dropdown-item">
                            <Navbar.Link href='/multiplayer'>
                                MultiPlayer
                            </Navbar.Link>
                        </div>
                    </Dropdown>
                </div>
            </Navbar.Collapse>
        </Navbar>
    )
}