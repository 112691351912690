import { Canvas } from "@react-three/fiber";

import { Header } from "../../Components/Header";
import Experience from "./Experience";
import { SocketManager } from "./SocketManager";

export const MultiPlayerGame = () => {
    SocketManager();

    return (
        <>
            <div>
                <Header />
                <div className="ovCanvas">
                    <Canvas
                        flat
                        camera={{
                            fov: 30,
                            near: 0.1,
                            far: 50,
                            position: [0, 0, 8]
                        }}
                    >
                        {/* <color args={['black']} attach="background" /> */}
                        <Experience />
                    </Canvas>
                </div>

            </div>

        </>
    )

}