import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Home } from './Home';
import { EnvMapWorld } from './Pages/EnvMapWorld';
import { GtWorld } from './Pages/GtWorld';
import { PortalWorld } from './Pages/PortalWorld';
import { PhysicsWorld } from './Pages/PhysicsWorld';
import { EnvMapSphere } from './Pages/EnvMapSphere';
// import {WalkThrough} from './Pages/WalkThrough';
import { ExtrudeWorld } from './Pages/ExtrudeWorld';
import { VideoCallingExample } from './Pages/VideoCallingExample';
import { WorldWithCharacter } from './Pages/WorldWithCharacter';
import { SvgExtrusionWorld } from './Pages/SvgExtrusionWorld';
import { ContentDoor } from './Pages/ContentDoor';
import { MultiPlayerGame } from './Pages/MultiPlayerGame';
import { MultiPlayer } from './Pages/MultiPlayer';
import { LandscapeExperience } from './Pages/LandscapeWorld/LandscapeExperience';
import { HospitalWorld } from './Pages/HospitalWorld';
import { PdfViewer } from './Pages/PDFViewer'
import { DoctorOfficeExperience } from './Pages/DoctorOfficeExperience';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/env-map-example" element={<EnvMapWorld />} />
        <Route path="/content-door" element={<ContentDoor />} />
        <Route path="/gt-world" element={<GtWorld />} />
        <Route path="/portal-world" element={<PortalWorld />} />
        <Route path="/video-calling-example" element={<VideoCallingExample />} />
        <Route path="/physics-world" element={<PhysicsWorld />} />
        <Route path="/world-with-character" element={<WorldWithCharacter />} />
        <Route path="/env-map-sphere" element={<EnvMapSphere />} />
        <Route path="/landscape" element={<LandscapeExperience />} />
        <Route path="/hospital-world" element={<HospitalWorld />} />
        <Route path="/view-pdf" element={<PdfViewer />} />

        {/* LandscapeExperience */}
        {/* <Route path="/walk" element={<WalkThrough />} /> */}
        <Route path="/extrude-world" element={<ExtrudeWorld />} />
        <Route path="/svg-extrude-world" element={<SvgExtrusionWorld />} />
        <Route path="/multiplayer-game" element={<MultiPlayerGame />} />
        <Route path="/multiplayer" element={<MultiPlayer />} />
        <Route path="/doctoroffice" element={<DoctorOfficeExperience />} />

      </Routes>
    </Router>
  );
}

export default App;
