import {

    Environment,
    OrbitControls,
    TransformControls,
    Html,
    KeyboardControls

} from "@react-three/drei";
import { useEffect, useRef, useState } from "react";
import * as THREE from 'three';
import AgoraIOVideoCall from "../../Components/AgoraIO";
import { PatientCard } from "../../Components/PatientCard";

import './style.css'
import PatientVitals from "../../Components/PatientVitals";
import { useFrame, useThree } from "@react-three/fiber";
import PDFViewerExperience from "../PDFViewer/PDFViewerExperience";
import Character from "./Charecter";


export default function HospitalWorldExperience() {

    // const [cameraRotation, setCameraRotation] = useState({ x: 0, y: 0 });
    // const [cameraPosition, setCameraPosition] = useState({ x: 0, y: -1, z: 10 });

    // useEffect(() => {
    //     const handleKeyDown = (event) => {
    //         const { key } = event;
    //         console.log("key", key);

    //         const rotationSpeed = 0.2;
    //         const moveSpeed = 0.1;

    //         const newRotation = { ...cameraRotation };
    //         const newCameraPosition = { ...cameraPosition };

    //         switch (key) {
    //             case 'ArrowLeft':
    //                 newRotation.y += rotationSpeed;
    //                 break;
    //             case 'ArrowRight':
    //                 newRotation.y -= rotationSpeed;
    //                 break;
    //             case 'ArrowUp':

    //                 if (newRotation.y > 2.8 && newRotation.y < 4) {
    //                     // Move the camera forward
    //                     console.log("rotaion", newRotation);
    //                     newCameraPosition.z += moveSpeed;
    //                 } else {
    //                     // Reverse the camera movement when angle is 180 degrees
    //                     newCameraPosition.z -= moveSpeed;
    //                 }
    //                 break;
    //             case 'ArrowDown':

    //                 if (newRotation.y > 2.8 && newRotation.y < 4) {
    //                     // Move the camera backward
    //                     newCameraPosition.z -= moveSpeed;
    //                 } else {
    //                     // Reverse the camera movement when angle is 180 degrees
    //                     newCameraPosition.z += moveSpeed;
    //                 }
    //                 break;
    //             default:
    //                 return;
    //         }


    //         setCameraRotation(newRotation);
    //         setCameraPosition(newCameraPosition);
    //         console.log(`New Camera Rotation: x=${newRotation.x}, y=${newRotation.y}`);
    //     };

    //     // Attach the event listener
    //     window.addEventListener('keydown', handleKeyDown);

    //     // Remove the event listener when the component unmounts
    //     return () => {
    //         window.removeEventListener('keydown', handleKeyDown);
    //     };
    // }, [cameraRotation, cameraPosition]);

    // const { camera } = useThree()
    // camera.rotation.set(cameraRotation.x, cameraRotation.y, 0);
    // camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
    // console.log("sdsdg", cameraRotation);


    // const distanceFromCamera = 1;

    // const characterRef = useRef();

    // // Use useFrame to update the character's position and rotation
    // useFrame(() => {
    //     // Ensure the character mesh exists and the camera is defined
    //     if (characterRef.current && camera) {
    //         // Set the character's position to be closer to the camera
    //         const characterPosition = camera.position.clone();
    //         characterPosition.add(camera.getWorldDirection(new THREE.Vector3()).multiplyScalar(distanceFromCamera));

    //         characterPosition.y = -1.5;
    //         characterRef.current.position.copy(characterPosition);

    //         // Set the character's rotation to match the camera's rotation
    //         characterRef.current.rotation.copy(camera.rotation);
    //     }
    // });


    return <>


        <Environment background preset="city" />
        <OrbitControls
        // enableZoom={false}
        />
        <ambientLight intensity={0.8} />
        {/* <mesh scale={.75} >
            <sphereGeometry />
            <meshStandardMaterial color={"red"} />
        </mesh> */}


        <mesh
        // position={characterPosition}
        // ref={characterRef}
        >
            {/* Your character geometry and material */}


            <sphereGeometry args={[0.2, 32, 32]} />
            {/* <boxGeometry scale={0.2} /> */}
            <meshBasicMaterial color={0xff0000} />
        </mesh>

        <group >
            <mesh
                position={[0, -1, 0]}
            // side={THREE.DoubleSide}
            >
                {/* <boxGeometry args={[2.95, 1.5, 2.95]} /> */}
                <planeGeometry args={[6, 4]} />
                <meshBasicMaterial color={0xaaaaaa} side={THREE.DoubleSide} />

            </mesh>
            <Html transform position={[0, -1, 0]}
                distanceFactor={1.1}
            // rotation={[0, 1.57, 0]}
            >
                <AgoraIOVideoCall />
            </Html>
        </group>

        <group >
            <mesh
                position={[-4.8, -1, 3.3]}
                // side={THREE.DoubleSide}
                rotation={[0, Math.PI / 3, 0]}
            >
                {/* <boxGeometry args={[2.95, 1.5, 2.95]} /> */}
                <planeGeometry args={[4.2, 2.3]} />
                <meshBasicMaterial color={0xffffff} side={THREE.DoubleSide} />

            </mesh>
            <Html transform position={[-4.8, -1, 3.3]}
                rotation={[0, Math.PI / 3, 0]}
                distanceFactor={1.7}
            // rotation={[0, 1.57, 0]}
            >
                <div className="flex">
                    <div style={{ width: '50%', marginRight: '5px' }}>
                        <PatientCard
                            name="Mohammad Zaid"
                            disease="Allergy"
                            age="21"
                            gender="M"
                            language="English"
                            location="Jlp"
                            weight="45"
                            height="180"
                            style={{ width: '100%', height: '100%' }} />
                    </div>
                    <div style={{ width: '50%' }}>
                        {/* <PatientCard name="Moahammad" style={{ width: '100%', height: '100%' }} /> */}
                        <PatientVitals
                            name="Moahammad Zaid"
                            gender="Male"
                            weight="75"
                            height="180"
                            bloodPressure="120/80"
                            conditions="Hypertension, Diabetes"
                        />

                    </div>
                </div>
            </Html>
        </group>

        <group >
            <mesh
                position={[4.8, -1, 3.3]}
                // side={THREE.DoubleSide}
                rotation={[0, -Math.PI / 3, 0]}
            >
                {/* <boxGeometry args={[2.95, 1.5, 2.95]} /> */}
                <planeGeometry args={[4.2, 2.3]} />
                <meshBasicMaterial color={0xffffff} side={THREE.DoubleSide} />

            </mesh>


            <Html transform position={[4.8, -1, 3.3]}
                rotation={[0, -Math.PI / 3, 0]}
                distanceFactor={1.7}
            // rotation={[0, 1.57, 0]}
            >
                <div style={{ width: '800px', height: '500px', overflowY: 'scroll' }}>
                    <PDFViewerExperience />
                </div>
                <div className="flex">
                    <div style={{ width: '50%', marginRight: '5px' }}>

                    </div>
                    <div style={{ width: '50%' }}>

                    </div>
                </div>
            </Html>
        </group>

        {/* <group >
            <Html transform position={[0, -1, 20]}
                rotation={[0, Math.PI, 0]}
                distanceFactor={1.7}
            // rotation={[0, 1.57, 0]}
            >
                <div className="flex">
                    <div style={{ width: '50%', marginRight: '5px' }}>
                        <iframe src="http://localhost:3000/hospital-world"
                            width={1073} height={525} title='wall_iframe'
                            allow='xr-spatial-tracking'
                        />
                    </div>
                    <div style={{ width: '70%' }}>

                    </div>
                </div>
            </Html>
        </group> */}

        <group>
            <Html
                transform
                position={[0, -1, 15]}
                rotation={[0, Math.PI, 0]}
                distanceFactor={1.7}
            >
                <div className="flex">
                    <div style={{ width: '100%', marginRight: '5px' }}>
                        <iframe
                            width="560" // Set the width as needed
                            height="315" // Set the height as needed
                            src="https://www.youtube.com/embed/VIDEO_ID_HERE" // Replace with your YouTube video URL
                            title="YouTube Video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                    <div style={{ width: '70%' }}>
                        {/* Optional content */}
                    </div>
                </div>
            </Html>
        </group>
        {/* </KeyboardControls> */}
    </>


}

