import { useGLTF } from "@react-three/drei"
import * as THREE from 'three'
export default function PortalPlane() {
    const nodes = useGLTF('./PortalWorld/portal/model/portal.glb')
    console.log("nodes", nodes)
    return (
        <>
            <mesh position-y={-0.001} scale={4}
                rotation={[1.57, 0, 0]}
                // side={THREE.DoubleSide}
                onClick={(event) => event.stopPropagation()}
                onPointerEnter={() => document.body.style.cursor = 'default'}
            >
                <planeGeometry />
                <meshBasicMaterial color={"yellow"} />
            </mesh>
        </>
    )
}