import { Canvas } from "@react-three/fiber";

import { Header } from "../../Components/Header";
import Experience from "./Experience";
import { SocketManager } from "./SocketManager";
export const MultiPlayer= () => {
    SocketManager();
    return (
        <>

            <div>
                <Header />
                <div className="ovCanvas">
                    <Canvas shadows camera={{ position: [3, 3, 3], fov: 30 }}>
                        <color attach="background" args={["#ececec"]} />
                        <Experience />
                    </Canvas>
                </div>

            </div>

        </>
    )

}