import React from 'react';

const Card = ({ title, description, imageUrl }) => {
  return (
    <div className="card">
      <img src={imageUrl} alt={title} />
      <div className="card-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Card;




// import {
//     useTexture, Environment, MeshPortalMaterial, RoundedBox,
//     Text, CameraControls, useCursor, ScrollControls,
//     Scroll, Image, Html
// } from '@react-three/drei'
// import * as THREE from 'three'
// import { useThree, useFrame, } from '@react-three/fiber'
// import { useRef, useState, useEffect } from 'react'
// import { gsap } from 'gsap'



// import King from '../../Components/King/King'
// import Adventurer from '../../Components/Adventurer/Adventurer'
// import Swat from '../../Components/Swat/Swat'

// import Card from './Card'



// // const loadAudio = (url) => {
// //     return new Promise((resolve, reject) => {
// //         const audio = new Audio(url);
// //         audio.addEventListener('canplaythrough', () => {
// //             resolve(audio);
// //         });
// //         audio.addEventListener('error', (error) => {
// //             reject(error);
// //         });
// //         audio.load();
// //     });
// // };

// export default function ContentDoorExperience() {
//     const [active, setActive] = useState(null)
//     const [hovered, setHovered] = useState(null)
//     const [effect, setEffect] = useState(null)
//     const cameraControlsRef = useRef();
//     const roundBoxRef = useRef()
//     const [horizontalOffset, setHorizontalOffset] = useState(0);
//     const contentDoorStageRef = useRef();


//     useCursor(hovered)
//     const { scene, camera } = useThree()

//     const handleMouseWheel = (event) => {
//         if (contentDoorStageRef.current.children.length > 3) {
//             setHorizontalOffset((prevOffset) => prevOffset - event.deltaX);
//         }

//     };


//     const initialHorizontalOffset = -157;

//     useEffect(() => {
//         // Set the initial horizontal offset when the page loads
//         setHorizontalOffset(initialHorizontalOffset);

//         // Add mouse wheel event listener on component mount
//         window.addEventListener('wheel', handleMouseWheel, { passive: true });

//         // Clean up the event listener on component unmount
//         return () => {
//             window.removeEventListener('wheel', handleMouseWheel);
//         };
//     }, []);
//     // const { camera } = useThree()

//     useEffect(() => {
//         if (active) {
//             const targetPosition = new THREE.Vector3();
//             scene.getObjectByName(active).getWorldPosition(targetPosition)
//             cameraControlsRef.current.setLookAt(
//                 0, 0, 5,
//                 targetPosition.x,
//                 targetPosition.y,
//                 targetPosition.z
//             )
//         } else {
//             cameraControlsRef.current.setLookAt(
//                 0, 0, 10,
//                 0, 0, 0,
//             )
//         }
//     }, [active])
//     // useEffect(() => {
//     //     if (effect !== null) {
//     //         gsap.to(roundBoxRef.current.position, {
//     //             duration: 0.2,
//     //             x: Math.random() * 0.1 - 0.05,
//     //             y: Math.random() * 0.1 - 0.05,
//     //             z: Math.random() * 0.1 - 0.05,
//     //             repeat: 4,
//     //             yoyo: true,
//     //         });
//     //     }
//     // }, [effect])

//     // useEffect(() => {
//     //     if (hovered !== null) {
//     //         gsap.to(roundBoxRef.current.position, {
//     //             duration: 0.2,
//     //             x: Math.random() * 0.1 - 0.05,
//     //             y: Math.random() * 0.1 - 0.05,
//     //             z: Math.random() * 0.1 - 0.05,
//     //             repeat: 4,
//     //             // yoyo: true,
//     //         });
//     //     }
//     // }, [hovered])

//     // useEffect(() => {
//     //     // const audio = loadAudio('./PortalWorld/portal/music/music.mp3');
//     //     // audio.loop = true;
//     //     // audio.volume = 0.3;
//     //     // if (hovered !== null) {
//     //     //     audio.play()
//     //     // }
//     //     // return () => audio.pause()
//     //     const audioPromise = loadAudio('./PortalWorld/portal/music/music.mp3');
//     //     audioPromise.then((audio) => {
//     //         audio.loop = true;
//     //         audio.volume = 0.6;
//     //         if (hovered !== null) {
//     //             audio.play();
//     //         }
//     //     }).catch((error) => {
//     //         console.error('Error loading audio:', error);
//     //     });
//     //     return () => {
//     //         if (audioPromise) {
//     //             audioPromise
//     //                 .then((audio) => {
//     //                     audio.pause();
//     //                 })
//     //                 .catch((error) => {
//     //                     console.error('Error pausing audio:', error);
//     //                 });
//     //         }
//     //     };
//     // }, [hovered])


//     const kingPositionX = horizontalOffset * 0.01;
//     const adventurerPositionX = -2.5 + horizontalOffset * 0.01;
//     const swatPositionX = 2.5 + horizontalOffset * 0.01;
//     const swatPositionX1 = 5.0 + horizontalOffset * 0.01;
//     const swatPositionX2 = -5.0 + horizontalOffset * 0.01;


//     const horizontalOffsetRadians = (horizontalOffset * 0.01) % (2 * Math.PI);

//     const radius = 10;
//     const commonAngle = horizontalOffsetRadians;

//     return <>
//         <CameraControls ref={cameraControlsRef}
//         // minDistance={0}
//         // maxDistance={-5}
//         />
//         <ambientLight intensity={0.5} />
//         {/* <directionalLight
//             position={[0, 3, 0]} // Set the position of the spotlight
//             angle={Math.PI / 6} // Set the angle of the spotlight (controls the focus area)
//             // penumbra={1} // Set the penumbra (controls the softness of the light edges)
//             intensity={1} // Set the intensity of the light

//         /> */}

//         {/* <Environment preset='sunset' /> */}
//         {/* <mesh position={[0, 0, -10]} >
//             <boxGeometry scale={2} />
//             <meshStandardMaterial color={"yellow"} />
//         </mesh> */}

//         <group ref={contentDoorStageRef}>
//             <ContentDoorStage texture={
//                 './Texture/interior_views_galaxy.jpg'
//             } name={'King'} color={'#063240'}
//                 active={active}
//                 roundBoxRef={roundBoxRef}
//                 setActive={setActive}
//                 effect={effect} setEffect={setEffect}
//                 hovered={hovered}
//                 setHovered={setHovered}
//                 // position-x={kingPositionX}
//                 // position-z={circularMotion(kingPositionX, 5)[1]}
//                 position-x={radius * Math.cos(commonAngle)}
//                 position-z={radius * Math.sin(commonAngle)}
//             // componentDoorStageRef={componentDoorStageRef}
//             >
//                 <King position-y={-1.3} hovered={hovered === 'King'} />

//             </ContentDoorStage>
//             <ContentDoorStage texture={
//                 './Texture/scifi_city.jpg'
//             } //position-x={adventurerPositionX}
//                 //position-z={circularMotion(adventurerPositionX, 5)[1]}
//                 position-x={radius * Math.cos(commonAngle + 0.5)}
//                 position-z={radius * Math.sin(commonAngle + 0.5)}
//                 // rotation-y={Math.PI / 6}
//                 name={'Adventure'} color={'#a62d0f'}
//                 active={active} setActive={setActive}
//                 effect={effect} setEffect={setEffect}
//                 hovered={hovered} setHovered={setHovered}
//                 roundBoxRef={roundBoxRef}
//             // componentDoorStageRef={componentDoorStageRef}
//             // position-y={adventurerPositionY}

//             >
//                 <Adventurer position-y={-1.3} hovered={hovered === 'Adventure'} />
//             </ContentDoorStage>
//             <ContentDoorStage texture={
//                 './Texture/scifi_water_world.jpg'
//             } //position-x={swatPositionX}
//                 //position-z={circularMotion(swatPositionX, 5)[1]}
//                 position-x={radius * Math.cos(commonAngle + 1)}
//                 position-z={radius * Math.sin(commonAngle + 1)}
//                 // rotation-y={-Math.PI / 8}
//                 name={'Swat'} color={'#f2d9bf'}
//                 active={active} setActive={setActive}
//                 effect={effect} setEffect={setEffect}
//                 roundBoxRef={roundBoxRef}
//                 hovered={hovered} setHovered={setHovered}
//             // componentDoorStageRef={componentDoorStageRef}
//             // position-y={swatPositionY}
//             >
//                 <Swat position-y={-1.3} hovered={hovered === 'Swat'} />
//             </ContentDoorStage>
//             <ContentDoorStage texture={
//                 './Texture/scifi_water_world.jpg'
//             } //position-x={swatPositionX1}
//                 //position-z={circularMotion(swatPositionX1, 5)[1]}
//                 position-x={radius * Math.cos(commonAngle + 1.5)}
//                 position-z={radius * Math.sin(commonAngle + 1.5)}
//                 // rotation-y={-Math.PI / 8}
//                 name={'Swat1'} color={'#f2d9bf'}
//                 active={active} setActive={setActive}
//                 effect={effect} setEffect={setEffect}
//                 roundBoxRef={roundBoxRef}
//                 hovered={hovered} setHovered={setHovered}
//             // componentDoorStageRef={componentDoorStageRef}
//             // position-y={swatPositionY}
//             >
//                 {/* <Swat position-y={-1.3} hovered={hovered === 'Swat'} /> */}
//             </ContentDoorStage>
//             <ContentDoorStage texture={
//                 './Texture/scifi_water_world.jpg'
//             } //position-x={swatPositionX2}
//                 //position-z={circularMotion(swatPositionX2, 5)[1]}
//                 position-x={radius * Math.cos(commonAngle + 2)}
//                 position-z={radius * Math.sin(commonAngle + 2)}
//                 // rotation-y={-Math.PI / 8}
//                 name={'Swat2'} color={'#f2d9bf'}
//                 active={active} setActive={setActive}
//                 effect={effect} setEffect={setEffect}
//                 roundBoxRef={roundBoxRef}
//                 hovered={hovered} setHovered={setHovered}
//             // componentDoorStageRef={componentDoorStageRef}
//             // position-y={swatPositionY}
//             >
//                 {/* <Swat position-y={-1.3} hovered={hovered === 'Swat'} /> */}
//             </ContentDoorStage>
//             <ContentDoorStage texture={
//                 './Texture/scifi_water_world.jpg'
//             } //position-x={swatPositionX2}
//                 //position-z={circularMotion(swatPositionX2, 5)[1]}
//                 position-x={radius * Math.cos(commonAngle + 2.5)}
//                 position-z={radius * Math.sin(commonAngle + 2.5)}
//                 // rotation-y={-Math.PI / 8}
//                 name={'Swat3'} color={'#f2d9bf'}
//                 active={active} setActive={setActive}
//                 effect={effect} setEffect={setEffect}
//                 roundBoxRef={roundBoxRef}
//                 hovered={hovered} setHovered={setHovered}
//             // componentDoorStageRef={componentDoorStageRef}
//             // position-y={swatPositionY}
//             >
//                 {/* <Swat position-y={-1.3} hovered={hovered === 'Swat'} /> */}
//             </ContentDoorStage>
//         </group>
//         <Html>
//             <h1>fdfdf</h1>
//         </Html>



//     </>
// }


// const ContentDoorStage = ({ children, texture, name, color,
//     active, roundBoxRef, setActive, hovered, setHovered,
//     effect, setEffect, ...props
// }) => {
//     const map = useTexture(texture)
//     map.flipX = false
//     const portalMaterial = useRef()
//     const meshRef = useRef()

//     // useFrame((state, delta) => {
//     //     const worldOpen = active === name;
//     //     const horizontalOffset = state.mouse.x * 0.1;
//     //     const x = Math.cos(horizontalOffset) * 5;
//     //     const z = Math.sin(horizontalOffset) * 5;

//     //     // Rotate the component to face away from the camera
//     //     const cameraPosition = state.camera.position;
//     //     const componentPosition = meshRef.current.position;
//     //     const angle = Math.atan2(
//     //         componentPosition.x - cameraPosition.x,
//     //         componentPosition.z - cameraPosition.z
//     //     );

//     //     meshRef.current.rotation.y = angle + Math.PI; // Add Math.PI to rotate 180 degrees
//     //     meshRef.current.position.set(x, 0, z);
//     // });



//     return (

//         <group {...props} >
//             {/* <Card title={name} description="Card description" imageUrl={texture} /> */}
//             <Text fontSize={0.2}
//                 position={[0, -1.3, 0.051]}
//                 anchorY={'bottom'}>
//                 {name}
//                 <meshBasicMaterial color={color} toneMapped={false} />
//             </Text>
//             <RoundedBox args={[2, 3, 0.1]}
//                 active={active}
//                 name={name}
//                 ref={roundBoxRef}
//                 onDoubleClick={() => setActive(active === name ? null : name)}
//                 onPointerEnter={() => { setHovered(name); setEffect(name) }}
//                 onPointerLeave={() => { setHovered(null); setEffect(null) }}
//             >
//                 {/* <planeGeometry args={[2,3]} /> */}
//                 <MeshPortalMaterial side={THREE.DoubleSide}
//                     blend={active === name ? 1 : 0}
//                     ref={portalMaterial}
//                 // onDoubleClick={handlePortalClick}
//                 >
//                     <ambientLight intensity={1} />
//                     {/* <Environment preset='sunset' /> */}
//                     {/* here we can load 3d models or any world */}
//                     {children}
//                     <mesh ref={meshRef}>
//                         <sphereGeometry args={[10, 64, 64]} />
//                         <meshStandardMaterial map={map} side={THREE.BackSide} />
//                     </mesh>
//                 </MeshPortalMaterial>
//             </RoundedBox>
//         </group>
//     )
// };