import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import { Header } from "../../Components/Header";
import PhysicsWorldExperience from './PhysicsWorldExperience.jsx'

// const root = ReactDOM.createRoot(document.querySelector('#root'))

export const PhysicsWorld = () => {

    return <div>
    <Header />
    <div id="ov-canvas">
    <Canvas
            shadows
            camera={ {
                fov: 45,
                near: 0.1,
                far: 200,
                position: [ 4, 2, 6 ]
            } }
        >
            <PhysicsWorldExperience />
        </Canvas>
    </div>
  </div>;
    
  };