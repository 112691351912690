import { Html } from "@react-three/drei";
import {
  ErrorBoundary,
  FallbackProps,
  useErrorBoundary,
} from "react-error-boundary";
import "./style.css"

export function ProductsFetchingError(props: FallbackProps) {
  const { error, resetErrorBoundary } = props;

  return (
    <Html>
    <div id="container">
    <div className="error-handling" >
      <div className="text-center">
        <h1 className="text-5xl font-semibold text-red-600 mb-4">The world crashed! 🌍</h1>
        <p className="text-2xl text-gray-300 font-semibold">{error.message}</p>
        <button className="mt-4" onClick={resetErrorBoundary}>
          Reload Page
        </button>
      </div>
    </div>
    </div>
    
    </Html>
  );
}
