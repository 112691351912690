import React, { useState, useRef, useEffect } from 'react';
import { Html } from '@react-three/drei';
import { Document, Page, pdfjs } from 'react-pdf';
import ControlPanel from './ControlPanel';
import Loader from './Loader';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function PDFViewerExperience() {


  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false);
  }

  return (
    <>
      <div>
        <Loader isLoading={isLoading} />
        <section
          id="pdf-section"
          className="d-flex flex-column align-items-center w-100"
        >
          <ControlPanel
            scale={scale}
            setScale={setScale}
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            file="./Pdf/document.pdf"
          />
          <Document
            file="./Pdf/document.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} scale={scale} />
          </Document>
        </section>
      </div>
    </>

  );
}

