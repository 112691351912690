import React from "react";
import { Header } from "../../Components/Header";
import Landscape from "../../Components/Landscape/Landscape";

export const LandscapeExperience = () => {

  return (
    <>
      <Header />
      <Landscape />
      
    </>
  );
};
